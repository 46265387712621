import { Faq } from '@experian-digital/affordability-library';

export class FaqConfig {
  getFaqs(): Faq[] {
    return [
      {
        title: `How do I get more details on what went wrong with my customer's application?`,
        body: `Every application should have an Events tab under the More dropdown. Please click on this to view the lifecycle of the application.`,
      },
      {
        title: 'I receive a "Sorry, something went wrong" error. How do I resolve this?',
        body: `Please close the browser and try to login again. If the problem persists, please contact the Experian Service Desk.`,
      },
      {
        title: 'Which browsers are supported?',
        body: `Affordability Portal is supported for all the latest browsers in the market. For example: Safari, Google Chrome, Firefox, Opera etc.`,
      },
      {
        title: 'How do I get a new IP address whitelisted?',
        body: `Please contact Experian Service Desk with the company name and IP address to whitelist. (Provide Advisor support desk information).`,
      },
      {
        title: 'How do I get a new feature or functionality added?',
        body: `Please reach out to your account manager, if you are not aware of who it is you can contact Experian Service Desk with your request.`,
      },
    ];
  }
}
