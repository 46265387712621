<div
    class="accordion text-align-left"
    [ngClass]="{'accordion--legacy': isLegacy }"
    [id]="'accordion_' + idToScroll"
    [ngStyle]="{'scroll-margin-top': + headerHeight + 'px'}"
>
    <div
        class="accordion__title flex justify-content-between align-items-start p-sm"
        (click)="openAccordion()"
    >
        <div class="mr-sm">
            <ng-content select="[title]"></ng-content>
        </div>
        <div [@accordionIconRotate]="isOpen">
            <lib-down-arrow-icon class="icon-theme icon-xxs icon-link-blue"></lib-down-arrow-icon>
        </div>
    </div>
    <div
        class="accordion__body px-sm"
        [@accordionState]="isOpen"
        (@accordionState.done)="scrollItemIntoView()"
    >
        <div class="mb-sm">
            <ng-content select="[body]"></ng-content>
        </div>

    </div>
    <div
        class="line mx-sm"
        [ngClass]="{'line--legacy': isLegacy }"
    ></div>
</div>
