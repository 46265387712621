import { NgModule } from '@angular/core';
import { LibIconsModule } from '../icons/icons.module';
import { LibHeadingComponent } from './lib-heading.component';

@NgModule({
  imports: [LibIconsModule],
  declarations: [LibHeadingComponent],
  exports: [LibHeadingComponent],
})
export class LibHeadingModule {}
