import { DropdownItem } from '../../models/input.interface';
import { SortedGroup } from '../../models/sorted-group';

export class ArrayUtils {
  static groupBy(arr: Array<any>, groupByKeyFn: any): object {
    const response = arr.reduce((acc, c) => {
      const key = groupByKeyFn(c);
      acc[key] = acc[key] || [];
      acc[key].push(c);
      return acc;
    }, {});

    return response;
  }

  static toSortedArray(groupedData: object, sortByKeyFn: any): Array<any> {
    if (!groupedData) {
      return [];
    }
    return Object.keys(groupedData)
      .sort()
      .map((letter) => {
        const sortedElements = groupedData[letter].sort((a: any, b: any) => {
          return sortByKeyFn(a) > sortByKeyFn(b) ? 1 : -1;
        });
        return new SortedGroup(letter, sortedElements);
      });
  }

  static orderByDateDesc<T>(array: Array<T>, byFieldName: string): T[] {
    if (array) {
      array.sort((b, a) => {
        return ArrayUtils.getTime(new Date(a[byFieldName])) - ArrayUtils.getTime(new Date(b[byFieldName]));
      });
    }

    return array;
  }

  static orderByDateAsc<T>(array: Array<T>, byFieldName: string): T[] {
    if (array) {
      array.sort((b, a) => {
        return ArrayUtils.getTime(new Date(b[byFieldName])) - ArrayUtils.getTime(new Date(a[byFieldName]));
      });
    }

    return array;
  }

  static getTime(date?: Date): number {
    return date != null ? date.getTime() : 0;
  }

  static predefinedOrdering<T>(list: T[], predefinedOrder: string[], orderByFieldName: string): T[] {
    const orderedList: T[] = [];

    predefinedOrder.map((name: string) => {
      const item: T = list.find((obj) => obj[orderByFieldName].toLocaleLowerCase() === name);

      if (item) {
        orderedList.push(item);
      }
    });

    return orderedList;
  }

  static generateArrayOfYears(noOfYears: number): DropdownItem[] {
    var max = new Date().getFullYear();
    var min = max - noOfYears;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years.map((year) => ({ value: year, title: year }));
  }

  static toAlphabeticalOrder<T>(objArray: T[], fieldName: string): T[] {
    objArray.sort((a, b) => {
      var textA = a[fieldName].toLowerCase();
      var textB = b[fieldName].toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return objArray;
  }
}
