<svg
  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
  xmlns:svg="http://www.w3.org/2000/svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="80"
  height="80"
  viewBox="0 0 80 80"
  version="1.1"
  aria-labelledby="info-icon-title info-icon-desc"
  role="img"
  id="svg52"
>
  <title id="info-icon-title">Info icon</title>
  <desc id="info-icon-desc">This is an information icon.</desc>
  <defs id="defs37">
    <rect
      id="path-1"
      x="0"
      y="0"
      width="80"
      height="80"
    />
  </defs>
  <g
    id="Info-icon"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
    transform="matrix(1.5352218,0,0,1.5352218,-21.423105,-21.404105)"
  >
    <g id="g49">
      <mask
        id="df7ca45a-36ee-484e-baf4-e74db07f4ea3"
        fill="#ffffff"
      >
        <use
          xlink:href="#path-1"
          id="use39"
          x="0"
          y="0"
          width="100%"
          height="100%"
        />
      </mask>
      <g id="Mask" />
      <g
        mask="url(#df7ca45a-36ee-484e-baf4-e74db07f4ea3)"
        fill="#ba2f7d"
        class="fill"
        fill-rule="nonzero"
        id="g47"
      >
        <g
          transform="translate(14,14)"
          id="g45"
        >
          <path
            d="M 26,0 C 40.359403,0 52,11.640596 52,26 52,40.359403 40.359403,52 26,52 11.640596,52 0,40.359403 0,26 0,11.640596 11.640596,0 26,0 Z m 0,1.8571429 C 12.666268,1.8571429 1.8571429,12.666268 1.8571429,26 1.8571429,39.333732 12.666268,50.142857 26,50.142857 39.333732,50.142857 50.142857,39.333732 50.142857,26 50.142857,12.666268 39.333732,1.8571429 26,1.8571429 Z"
            id="Combined-Shape"
          />
          <path
            d="m 28.252841,10.826563 c 0.839587,0 1.547132,0.291142 2.122656,0.873437 0.575524,0.582295 0.863281,1.286454 0.863281,2.1125 0,0.826046 -0.291143,1.530205 -0.873437,2.1125 -0.582295,0.582295 -1.286454,0.873437 -2.1125,0.873437 -0.812504,0 -1.509893,-0.291142 -2.092188,-0.873437 -0.582294,-0.582295 -0.873437,-1.286454 -0.873437,-2.1125 0,-0.826046 0.287757,-1.530205 0.863281,-2.1125 0.575524,-0.582295 1.276298,-0.873437 2.102344,-0.873437 z m 1.259375,9.34375 -4.164063,14.442187 c -0.230209,0.826046 -0.345312,1.367707 -0.345312,1.625 0,0.148959 0.06094,0.28776 0.182812,0.416406 0.121876,0.128647 0.250521,0.192969 0.385938,0.192969 0.230209,0 0.460415,-0.101561 0.690625,-0.304688 0.609378,-0.501044 1.340621,-1.408326 2.19375,-2.721875 l 0.690625,0.40625 c -2.044802,3.561477 -4.218218,5.342188 -6.520313,5.342188 -0.880212,0 -1.580987,-0.247133 -2.102343,-0.741406 -0.521357,-0.494274 -0.782032,-1.120569 -0.782032,-1.878907 0,-0.501044 0.115103,-1.137496 0.345313,-1.909375 l 2.823437,-9.709375 c 0.270835,-0.934379 0.40625,-1.638539 0.40625,-2.112499 0,-0.297919 -0.128644,-0.561978 -0.385937,-0.792188 -0.257293,-0.23021 -0.609373,-0.345313 -1.05625,-0.345313 l -0.525586,0.01143 c -0.06602,0.0025 -0.13457,0.0055 -0.205664,0.0089 l 0.264062,-0.8125 6.885938,-1.117187 z"
            id="i"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
