import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-button',
  templateUrl: './lib-button.component.html',
  styleUrls: ['./lib-button.component.scss'],
})
export class LibButtonComponent {
  @Input() buttonText: string;
  @Input() styleName: string;
  @Input() buttonType = 'button';
  @Input() isIconButton: boolean;
  @Input() isButtonDisabled = false;
  @Input() bgColor?: string;
  @Input() textColor?: string;
  @Input() borderStyle?: string;
  @Output() clickEvent = new EventEmitter();

  triggerClickEvent(event: any): void {
    this.clickEvent.emit(event);
  }
}
