import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LibFillCrossRoundIconModule } from '../icons/lib-fill-cross-round-icon/lib-fill-cross-round-icon.module';
import { LibFillTickRoundIconModule } from '../icons/lib-fill-tick-round-icon/lib-fill-tick-round-icon.module';
import { LibTextInputModule } from '../lib-input/lib-input.module';
import { LibDateInputComponent } from './lib-date-input.component';

@NgModule({
  imports: [CommonModule, LibTextInputModule, LibFillTickRoundIconModule, LibFillCrossRoundIconModule, ReactiveFormsModule],
  declarations: [LibDateInputComponent],
  exports: [LibDateInputComponent],
})
export class LibDateInputModule {}
