import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LibInputInterface } from '../../models/input.interface';

@Component({
  selector: 'lib-input',
  templateUrl: './lib-input.component.html',
  styleUrls: ['./lib-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LibTextInputComponent implements LibInputInterface {
  @Input() control = new UntypedFormControl();
  @Input() placeholder: string;
  @Input() labelId: string;
  @Input() labelText: string;
  @Input() errorMessage: string;
  @Input() appendContent: boolean;
  @Input() isValidationOn: boolean;
  @Input() type? = 'text';
  @Input() readOnly?: boolean;
  @Input() description?: string;
  @Input() spellcheck? = false;
  @Output() notifyInputBlur: EventEmitter<UntypedFormControl> = new EventEmitter();

  get fieldTouchedAndInvalid(): boolean {
    return this.control.touched && !this.control.valid && this.isValidationOn;
  }

  get showGreenTick() {
    return this.control.valid && this.control.touched && this.isValidationOn;
  }

  inputBlur() {
    this.notifyInputBlur.emit(this.control);
  }

  get isInvalid(): boolean {
    return this.fieldTouchedAndInvalid && !!this.errorMessage;
  }
}
