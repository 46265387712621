import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppInsightsService, AuthService, Faq, STORAGE_KEYS } from '@experian-digital/affordability-library';
import { getClientSettings } from '@portal-shared/configs/auth-config';
import { STANDARD_LOADER } from '@portal-shared/models/enums/loading.enum';
import { ApplicationConfigService } from '@portal-shared/services/config.service';
import { TimeoutService } from '@portal-shared/services/timeout.service';
import { Error, Loading, STORE_FIELDS, StoreState } from '@portal-store/models/store.model';
import { StoreService } from '@portal-store/store.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { FaqConfig } from './advisor/config/faq.config';

@Component({
  selector: 'aff-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('.2s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.2s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  subscriptions: Subscription[] = [];
  isScrollToTopIconVisible = false;
  loadingInitial$: Observable<Loading>;
  error$: Observable<Error>;
  showFaq: boolean = true;
  currentRoute: Subject<unknown> = new Subject();
  isFaqModalVisible = false;
  faqList: Faq[] = new FaqConfig().getFaqs();
  hideNavBar = false;
  isOktaLoginMode = false;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private store: StoreService,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private timeoutService: TimeoutService,
    public appInsights: AppInsightsService,
  ) {
    this.sessionTimeoutListener();
    if (environment.production) {
      this.appInsights.initAppInsights(ApplicationConfigService.config.instrumentationKey);
    }
  }

  @HostListener('mouseenter')
  @HostListener('mouseover')
  @HostListener('mousemove')
  sessionTimeoutListener(): void {
    this.timeoutService.initTimer();
  }
  @HostListener('beforeunload')
  beforeUnloadListener(): void {
    this.timeoutService.beforeunloadListener();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.scrollY >= 200) {
      this.isScrollToTopIconVisible = true;
      this.cd.detectChanges();
      return;
    }
    this.isScrollToTopIconVisible = false;
    this.cd.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.oAuthService.configure(getClientSettings(this.isOktaLogin()));
    this.authService.runInitialLoginSequence();
    this.resetErrorInStoreOnPageChange();
    this.store.add<Loading>(STORE_FIELDS.loadingInitial, STANDARD_LOADER);
    this.store.add<Error>(STORE_FIELDS.error, null);
    this.loadingInitial$ = this.getStoreState(STORE_FIELDS.loadingInitial);
    this.error$ = this.getStoreState<Error>(STORE_FIELDS.error);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.store.add(STORE_FIELDS.loading, STANDARD_LOADER);
        if (event.url.includes('work-report')) {
          this.showFaq = false;
        }
      } else if (event instanceof NavigationEnd) {
        this.store.add<Loading>(STORE_FIELDS.loading, null);
        this.store.add<Loading>(STORE_FIELDS.loadingInitial, null);
        this.hideNavBar = false;
        if (event.url.includes('select-department')) {
          this.hideNavBar = true;
        }
      }
    });
  }
  navigateTo(): void {
    const error = this.store.get<Error>(STORE_FIELDS.error);
    this.router.navigateByUrl(error.errorLink);
    this.store.add<Error>(STORE_FIELDS.error, null);
  }

  resetErrorInStoreOnPageChange(): void {
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
        this.store.add(STORE_FIELDS.error, null);
      }),
    );
  }

  scrollToTop($element?): void {
    if ($element) {
      $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      return;
    }
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  private getStoreState<T>(storeField: string): Observable<T> {
    return this.store.globalStateChanged.pipe(map((store: StoreState) => store && store[storeField]));
  }

  private isOktaLogin(): boolean {
    if (new URLSearchParams(window.location.search).get('oktaLogin')) {
      localStorage.setItem(STORAGE_KEYS.oktaLogin, 'true');
    }
    return !!localStorage.getItem(STORAGE_KEYS.oktaLogin);
  }
}
