<svg
  width="30"
  height="30"
  viewBox="0 0 30 30"
  xmlns="http://www.w3.org/2000/svg"
  aria-labelledby="fill-tick-round-icon-title fill-tick-round-icon-desc"
  role="img"
>
  <title id="fill-tick-round-icon-title">Tick icon</title>
  <desc id="fill-tick-round-icon-desc">This is an tick icon for success.</desc>
  <g
    fill="none"
    fill-rule="evenodd"
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      fill="#68A018"
      class="fill"
    />
    <path
      fill="#FFF"
      fill-rule="nonzero"
      d="M12.075 19.949h-.055l-.054-.052c-.055 0-.11-.05-.164-.102l-.055-.051-3.612-3.847c-.329-.359-.329-.923.054-1.23.164-.154.383-.257.602-.257.22 0 .493.103.657.257l2.956 3.18 7.114-7.59c.165-.154.384-.257.657-.257.22 0 .438.103.602.256.329.308.383.872.055 1.231l-7.826 8.308c-.055.051-.11.102-.165.102h-.054l-.055.052c-.11.051-.219.051-.328.051-.11 0-.22 0-.329-.051z"
    />
  </g>
</svg>
