import { AccountsByConsentDetails, AvailableDepartment } from '@experian-digital/affordability-library';

export interface StoreState {
  loading: Loading;
  loadingInitial: Loading;
  error: Error;
  modal: Modal;
  customerPageDetails: PaginationMetaData;
  transactionsRefreshState: TransactionsRefreshState;
  customerAccountsByConsent: AccountsByConsentDetails[];
  availableDepartments: AvailableDepartment[];
}

export interface Loading {
  imageUrl: string;
  infoText: string;
}

export interface Error {
  heading: string;
  subText?: string;
  linkText?: string;
  errorLink?: string;
  errorDetails?: ErrorLogDetails;
}

export interface ErrorLogDetails {
  correlationId?: string;
  timeStamp?: string;
}

export interface Modal {
  text1: string;
  text2?: string;
  text3?: string;
  linkUrl?: string;
  linkText?: string;
  buttonText?: string;
  buttonAction?: string;
  isSpinnerModal?: boolean;
  alertIcon?: boolean;
  errorDetails?: ErrorLogDetails;
}

export class BackButton {
  returnUrl: string;
  buttonText?: string;
  goToPreviousPage = false;

  constructor(returnUrl?: string, buttonText?: string) {
    this.returnUrl = returnUrl;
    this.buttonText = buttonText;
    if (!returnUrl) {
      this.goToPreviousPage = true;
    }
  }
}

export interface PaginationMetaData {
  currentPageSize: number;
  currentPageIndex: number;
  currentPageSearch: string;
  currentDateRange?: string;
}

export class TransactionsRefreshState {
  isLoading: boolean;
  isError: boolean;
  isUpToDate: boolean;
  noConsent?: boolean;
  errorDetails?: ErrorLogDetails;
}

export enum RefreshState {
  None,
  Loading,
  Error,
  UpToDate,
}

export enum STORE_FIELDS {
  loading = 'loading',
  loadingInitial = 'loadingInitial',
  error = 'error',
  localError = 'localError',
  modal = 'modal',
  backButton = 'backButton',
  footer = 'footer',
  department = 'department',
  application = 'application',
  customerListData = 'customerListData',
  accountList = 'accountList',
  transactionsRefreshState = 'transactionsRefreshState',
  customerAccountsByConsent = 'customerAccountsByConsent',
  availableDepartments = 'availableDepartments',
}
