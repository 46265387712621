import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WindowRef } from '../../shared/services/lib-window.service';
import { LibDownArrowIconModule } from '../icons/lib-down-arrow-icon/lib-down-arrow-icon.module';
import { LibAccordionItemComponent } from './lib-accordion-item.component';

@NgModule({
  imports: [CommonModule, LibDownArrowIconModule],
  declarations: [LibAccordionItemComponent],
  exports: [LibAccordionItemComponent],
  providers: [WindowRef],
})
export class LibAccordionItemModule {}
