import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'lib-modal',
  templateUrl: './lib-modal.component.html',
  styleUrls: ['./lib-modal.component.scss'],
})
export class LibModalComponent {
  @ContentChild('content') templateRef?: TemplateRef<ElementRef>;
  @Input() closeIconVisible = true;
  @Input() alignToTop = false;
  @Output() closeModalEvent = new EventEmitter();

  triggerCloseModal(): void {
    this.closeModalEvent.emit();
  }
}
