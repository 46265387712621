export enum ApplicationInsightsEvents {
  HomeClicked = 'HomeClicked',
  Logout = 'Logout',
  TimeoutLogout = 'TimeoutLogout',
  ApplicationSearch = 'ApplicationSearch',
  CustomerSearch = 'CustomerSearch',
  ApplicationClearSearch = 'ApplicationClearSearch',
  CustomerClearSearch = 'CustomerClearSearch',
  SendInviteReminder = 'SendInviteReminder',
  PassportPDFDownload = 'PassportPDFDownload',
  PassportCSVDownload = 'PassportCSVDownload',
  PayrollDataPDFDownload = 'PayrollDataPDFDownload',
  OBStatementPDFDownload = 'OBStatementPDFDownload',
  MonthlyOBStatementPDFDownload = 'MonthlyOBStatementPDFDownload',
  MonthlyOBStatementCSVDownload = 'MonthlyOBStatementCSVDownload',
  ExpandAppOverviewSpendByCategory = 'ExpandAppOverviewSpendByCategory',
  CollapseAppOverviewSpendByCategory = 'CollapseAppOverviewSpendByCategory',
  ExpandPassportCategory = 'ExpandPassportCategory',
  CollapsePassportCategory = 'CollapsePassportCategory',
  ExpandAccountTransactionCategory = 'ExpandAccountTransactionCategory',
  CollapseAccountTransactionCategory = 'CollapseAccountTransactionCategory',
  AccountSelected = 'AccountSelected',
  TransactionsFilteringByDate = 'TransactionsFilteringByDate',
  NewCustomerCreated = 'NewCustomerCreated',
  CustomerEdited = 'CustomerEdited',
  NewApplicationCreated = 'NewApplicationCreated',
  InviteReminderSent = 'InviteReminderSent',
  BackButtonClicked = 'BackButtonClicked',
  ViewOverview = 'ViewOverview',
  ViewAffordabilityPassport = 'ViewAffordabilityPassport',
  ViewEvents = 'ViewEvents',
  ViewAccountList = 'ViewAccountList',
  ViewAccountDetails = 'ViewAccountDetails',
  ViewCustomersList = 'ViewCustomersList',
  ViewApplicationList = 'ViewApplicationList',
  ViewCustomersDetails = 'ViewCustomersDetails',
  UnauthorisedAccess = 'UnauthorisedAccess',
  JointApplicationCreated = 'JointApplicationCreated',
  JointApplications = 'JointApplications',
  ApplicationDataRefresh = 'ApplicationDataRefresh',
}
