<ng-container *ngIf="isPreviousAddressEnabled">
  <label class="mb-xxs">Please enter past 3 years or last 3 addresses</label>

  <div
    *ngFor="let addressGroup of addressFormGroups?.controls; let i = index"
    class="address-accordion mb-xs"
    #address
    [attr.data-qa-id]="'address-form-groups'"
    [ngStyle]="{ 'padding-top': workOutOffset(i) + 'px', 'margin-top': -workOutOffset(i) + 'px' }"
  >
    <section
      class="address-accordion__header flex align-items-center px-sm"
      (click)="toggleAddressShown(i)"
    >
      <div class="flex">
        <div class="flex">
          <label class="mb-no">
            <span
              *ngIf="i === 0"
              class="regular"
            >
              {{ isAddressShown[i] ? 'Enter current address' : 'Your current address - ' }}
            </span>
            <span
              *ngIf="i > 0"
              class="regular"
            >{{
              isAddressShown[i] ? 'Enter previous address ' + i : 'Previous address ' + i + ' - '
              }}</span>
            <span
              class="small"
              style="line-height: 20px"
              *ngIf="!isAddressShown[i]"
            >
              {{
              (addressGroup?.get('houseName')?.value ? addressGroup?.get('houseName')?.value + ',' : '') +
              ' ' +
              (addressGroup?.get('houseNumber')?.value ? addressGroup?.get('houseNumber')?.value : '') +
              ' ' +
              (addressGroup?.get('streetName')?.value ? addressGroup?.get('streetName')?.value : '')
              }}
              - {{ '01/' + addressGroup?.get('month')?.value + '/' + addressGroup?.get('year')?.value }}
            </span>
          </label>
        </div>
      </div>

      <div class="flex align-items-center">
        <a
          *ngIf="i !== 0"
          class="ml-xs"
          href="javascript:void(0)"
          (click)="deleteAddress(i)"
        >Delete</a>
        <div
          [@rotatedState]="isAddressShown[i]"
          class="ml-xs"
        >
          <lib-chevron-icon class="icon-theme icon-xs"></lib-chevron-icon>
        </div>
      </div>
    </section>

    <div
      class="address-accordion__body"
      [ngClass]="{ 'address-accordion__body--closed': !isAddressShown[i] }"
      [@accordionBody]="isAddressShown[i]"
    >
      <div class="p-sm">
        <div class="mb-sm">
          <lib-address-picker
            [addressForm]="addressGroup"
            [addressList]="addressLists[i]"
            [selectedAddress]="selectedAddresses[i]"
            [loadingMessage]="addressLoadingMessageList[i]"
            (getAddresses)="fetchAddressList(i)"
            (getSelectedAddress)="fetchAddress($event, i)"
            [isNewAddressAdded]="isNewAddressAdded"
            [showCountry]="showCountry"
            (resetFormAddress)="resetAddress(i)"
            [showOldSpinner]="showOldSpinner"
          ></lib-address-picker>
        </div>

        <div
          style="margin-top: -5px"
          *ngIf="showMoveInDate"
        >
          <lib-dropdown
            *ngIf="dateInfo?.months"
            title="Month"
            placeholder="- Select month -"
            [list]="dateInfo.months"
            [control]="addressGroup?.get('month')"
            (onChange)="onDropdownChange(i, 'month')"
          >
          </lib-dropdown>
          <lib-dropdown
            *ngIf="dateInfo?.years"
            title="Year"
            placeholder="- Select year -"
            [list]="dateInfo.years"
            [control]="addressGroup?.get('year')"
            (onChange)="onDropdownChange(i, 'year')"
          >
          </lib-dropdown>
        </div>

        <div
          class="invalid-field"
          *ngIf="
            (!addressGroup?.get('month')?.value && addressGroup?.get('month')?.touched) ||
            (!addressGroup?.get('year')?.value && addressGroup?.get('year')?.touched)
          "
          [attr.data-qa-id]="'move-in-date-error'"
        >
          Please provide a valid move in date.
        </div>
        <div
          class="invalid-field"
          *ngIf="addressGroup?.get('year')?.getError('isCurrentAfterNextDate') || addressGroup?.get('month')?.getError('isCurrentAfterNextDate')"
          [attr.data-qa-id]="'move-in-date-error-year'"
        >
          The address provided needs to be prior to next previous address.
        </div>
        <div
          class="invalid-field"
          *ngIf="addressGroup?.get('year')?.getError('isCurrentAfterPreviousDate') || addressGroup?.get('month')?.getError('isCurrentAfterPreviousDate')"
          [attr.data-qa-id]="'move-in-date-error-year'"
        >
          The address provided needs to be prior to {{ i > 1 ? 'previous address' + i : 'your current address' }}.
        </div>
        <div
          class="invalid-field"
          *ngIf="addressGroup?.get('year')?.getError('chosenDateInFutureArray') || addressGroup?.get('month')?.getError('chosenDateInFutureArray')"
          [attr.data-qa-id]="'move-in-date-future-error'"
        >
          Date is in the future, please provide a valid move in date.
        </div>
      </div>
    </div>
  </div>

  <div class="ml-xs">
    <lib-button
      *ngIf="showButton"
      styleName="button--primary button--outline button--small button--icon"
      buttonText="Add another address"
      (clickEvent)="createNewAddressForm(addressFormGroups?.controls.length)"
      isIconButton="true"
      [attr.data-qa-id]="'add-another-address-button'"
    >
      <lib-plus-icon class="icon-theme icon-primary icon-xxs mr-xxs"></lib-plus-icon>
    </lib-button>
  </div>
</ng-container>

<ng-container *ngIf="!isMoveInDateEnabled && !isPreviousAddressEnabled">
  <div
    class="mb-sm"
    *ngFor="let addressGroup of addressFormGroups?.controls; let i = index"
  >
    <lib-address-picker
      [addressForm]="addressGroup"
      [addressList]="addressLists[i]"
      [selectedAddress]="selectedAddresses[i]"
      [loadingMessage]="addressLoadingMessageList[i]"
      (getAddresses)="fetchAddressList(i)"
      (getSelectedAddress)="fetchAddress($event, i)"
      [showCountry]="showCountry"
      [isNewAddressAdded]="false"
      (resetFormAddress)="resetAddress(i)"
      [showOldSpinner]="showOldSpinner"
    ></lib-address-picker>
  </div>
</ng-container>

<ng-container *ngIf="isMoveInDateEnabled && !isPreviousAddressEnabled">
  <div
    class="mb-sm"
    *ngFor="let addressGroup of addressFormGroups?.controls; let i = index"
  >
    <lib-address-picker
      [addressForm]="addressGroup"
      [addressList]="addressLists[i]"
      [selectedAddress]="selectedAddresses[i]"
      [loadingMessage]="addressLoadingMessageList[i]"
      (getAddresses)="fetchAddressList(i)"
      (getSelectedAddress)="fetchAddress($event, i)"
      [showCountry]="showCountry"
      [isNewAddressAdded]="false"
      (resetFormAddress)="resetAddress(i)"
      [showOldSpinner]="showOldSpinner"
    ></lib-address-picker>

    <div
      style="margin-top: -5px"
      *ngIf="showMoveInDate"
    >
      <lib-dropdown
        *ngIf="dateInfo?.months"
        title="Month"
        placeholder="- Select month -"
        [list]="dateInfo.months"
        [control]="addressGroup?.get('month')"
        (onChange)="onDropdownChange(i, 'month')"
      >
      </lib-dropdown>
      <lib-dropdown
        *ngIf="dateInfo?.years"
        title="Year"
        placeholder="- Select year -"
        [list]="dateInfo.years"
        [control]="addressGroup?.get('year')"
        (onChange)="onDropdownChange(i, 'year')"
      >
      </lib-dropdown>
    </div>

    <div
      class="invalid-field"
      *ngIf="
        (!addressGroup?.get('month')?.value && addressGroup?.get('month')?.touched) ||
        (!addressGroup?.get('year')?.value && addressGroup?.get('year')?.touched)
      "
      [attr.data-qa-id]="'move-in-date-error'"
    >
      Please provide a valid move in date.
    </div>
    <div
      class="invalid-field"
      *ngIf="addressGroup?.get('year')?.getError('isCurrentAfterPreviousDate') || addressGroup?.get('month')?.getError('isCurrentAfterPreviousDate')"
      [attr.data-qa-id]="'move-in-date-error-year'"
    >
      The address provided needs to be prior to {{ i > 1 ? 'previous address' + i : 'your current address' }}.
    </div>
    <div
      class="invalid-field"
      *ngIf="addressGroup?.get('year')?.getError('chosenDateInFutureArray') || addressGroup?.get('month')?.getError('chosenDateInFutureArray')"
      [attr.data-qa-id]="'move-in-date-future-error'"
    >
      Date is in the future, please provide a valid move in date.
    </div>
  </div>
</ng-container>
