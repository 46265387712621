import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibExperianLogoSvgModule } from '../icons/lib-experian-logo-svg/lib-experian-logo-svg.module';
import { LibHeaderLogoComponent } from './lib-header-logo.component';

@NgModule({
  declarations: [LibHeaderLogoComponent],
  imports: [CommonModule, LibExperianLogoSvgModule],
  exports: [LibHeaderLogoComponent],
})
export class LibHeaderLogoModule {}
