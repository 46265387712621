import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibPanelComponent } from './lib-panel.component';

@NgModule({
  declarations: [LibPanelComponent],
  imports: [CommonModule],
  exports: [LibPanelComponent],
})
export class LibPanelModule {}
