import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Error } from '@portal-store/models/store.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  buildError(customMessage: Error, error: HttpErrorResponse): Error {
    if (!customMessage) {
      return null;
    }
    const isRetryPossible = !error || (error?.status > 499 && error?.status <= 511);
    let customError: Error = {
      heading: customMessage?.heading || '',
      subText: customMessage?.subText || '',
      linkText: (isRetryPossible ? customMessage?.linkText : '') || '',
      errorLink: customMessage?.errorLink || '',
      errorDetails: this.doHeadersExist(error?.headers) ? this.buildErrorDetails(error) : null,
    };
    return customError;
  }

  private doHeadersExist(headers: HttpHeaders): boolean {
    return headers?.has('Runpath-Correlation-Id') || headers?.has('Date');
  }

  buildErrorDetails(error: HttpErrorResponse): any {
    return {
      correlationId: error?.headers?.get('Runpath-Correlation-Id') || '',
      timeStamp: new Date(error?.headers?.get('Date')).toLocaleString('en-GB') || '',
    };
  }
}
