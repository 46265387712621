import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import checkStrength from 'zxcvbn';
import { PasswordValidatorResult } from '../../models/password-validator-result.interface';

@Component({
  selector: 'lib-password-strength',
  templateUrl: './lib-password-strength.component.html',
  styleUrls: ['./lib-password-strength.component.scss'],
})
export class LibPasswordStrengthComponent implements OnChanges {
  @Input() password: string;
  @Input() displayFeedback = true;
  @Input() customValidators: ((password: string) => PasswordValidatorResult)[] = [];
  @Input() passwordControl?: UntypedFormControl;
  @Output() passwordScoreOutput: EventEmitter<number> = new EventEmitter();

  passwordScore = 0;
  maxPasswordScore = 4;
  passwordFeedback: string[] = [];
  customValidatorsPassed = true;

  ngOnChanges(): void {
    this.resetToDefaults();
    this.customValidation();
    this.calculatePasswordStrength();
  }

  resetToDefaults(): void {
    this.customValidatorsPassed = true;
    this.passwordFeedback = [];
  }

  customValidation(): void {
    if (
      (this.passwordControl && this.passwordControl.pristine) ||
      (!this.passwordControl && !this.password) ||
      this.customValidators.length === 0
    ) {
      return;
    }

    const customValidatorsResults: PasswordValidatorResult[] = this.customValidators.map(
      (validator: (password: string) => PasswordValidatorResult) => validator(this.password),
    );

    this.customValidatorsPassed = customValidatorsResults.every(result => result.isPassed);
    this.passwordFeedback = customValidatorsResults
      .filter(result => !result.isPassed)
      .map(result => result.errorMessage);
  }

  calculatePasswordStrength(): void {
    const zxcvbn = checkStrength(this.password);
    this.passwordScore = this.customValidatorsPassed ? zxcvbn.score : 0;
    this.passwordScoreOutput.emit(this.passwordScore);
    if (zxcvbn.feedback.warning.length > 0) {
      this.passwordFeedback = [...this.passwordFeedback, zxcvbn.feedback.warning];
    }
  }
}
