<div
  class="address-picker"
  [attr.data-qa-id]="'address-picker'"
>
  <ng-container *ngIf="!showManualAddress">
    <label [ngClass]="{
        'invalid-field':
          (addressForm.controls.postcode.invalid && addressForm.controls.postcode.touched) || noAddressesError
      }">Postcode</label>

    <lib-text-with-button
      [buttonLabel]="'Find address'"
      [control]="addressForm.controls.postcode"
      (clickEvent)="getAddressList($event)"
      class="display-block"
      [ngClass]="{ 'invalid-input': noAddressesError }"
    ></lib-text-with-button>

    <div
      *ngIf="addressForm.controls.postcode.invalid && addressForm.controls.postcode.touched"
      class="invalid-field"
      [attr.data-qa-id]="'provide-valid-postcode-error'"
    >
      Please provide a valid Postcode.
    </div>

    <div
      *ngIf="noAddressesError && addressForm.controls.postcode.valid"
      class="invalid-field"
      [attr.data-qa-id]="'something-wrong-error'"
    >
      Sorry something went wrong, please try again or enter your address manually.
    </div>

    <lib-scrollable-list
      [inputLabel]="'Select your address'"
      [list]="displayedAddressList"
      (itemClicked)="clickedListItem($event)"
      *ngIf="addressList?.length && !loadingMessage"
      class="display-block mt-xs"
    ></lib-scrollable-list>

    <a
      href=""
      (click)="toggleManualAddress($event)"
      class="display-block mt-xs"
      *ngIf="!loadingMessage"
      [attr.data-qa-id]="'manual-address-link'"
    >Enter address manually</a>
    <ng-container *ngIf="showOldSpinner; else v2Spinner">
      <lib-spinner
        class="display-block my-xs"
        *ngIf="loadingMessage"
      >
        <p class="my-xs">{{ loadingMessage }}</p>
      </lib-spinner>
    </ng-container>
    <ng-template #v2Spinner>
      <lib-spinner-v2
        class="display-block my-xs"
        *ngIf="loadingMessage"
      >
      </lib-spinner-v2>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="showManualAddress">
    <a
      href=""
      (click)="toggleManualAddress($event)"
      class="display-block mb-xs"
      [attr.data-qa-id]="'address-via-postcode-link'"
    >Look for address via postcode</a>

    <div
      class="pb-xs"
      [attr.data-qa-id]="'flat-input'"
    >
      <lib-input
        labelId="flat"
        [labelText]="'Flat'"
        [control]="addressForm.controls.flat"
        class="display-block"
        [isValidationOn]="addressForm.controls.flat.value"
      ></lib-input>
      <ng-container *ngTemplateOutlet="flat; context: { control: addressForm.controls?.flat }"></ng-container>
    </div>
    <!-- House name and number special validation - either or both -->

    <div
      class="pb-xs"
      [attr.data-qa-id]="'houseName-input'"
    >
      <label [ngClass]="{ 'invalid-field': isHouseNameOrNumberInvalid() || addressForm.controls.houseName.invalid }">House name</label>
      <lib-input
        labelId="house-name"
        [control]="addressForm.controls.houseName"
        class="address-picker__house-name-number display-block"
        [ngClass]="{ 'invalid-input': isHouseNameOrNumberInvalid() }"
        [isValidationOn]="!isHouseNameOrNumberInvalid() && addressForm.controls.houseName.value"
        [appendContent]="true"
        [attr.data-qa-id]="'house-name-input'"
      ></lib-input>

      <ng-container *ngTemplateOutlet="houseName; context: { control: addressForm.controls.houseName }"></ng-container>
    </div>

    <div
      class="pb-xs"
      [attr.data-qa-id]="'houseNumber-input'"
    >
      <label [ngClass]="{ 'invalid-field': isHouseNameOrNumberInvalid() || addressForm.controls.houseNumber.invalid }">House number</label>
      <lib-input
        labelId="house-number"
        [control]="addressForm.controls.houseNumber"
        class="address-picker__house-name-number display-block"
        [ngClass]="{ 'invalid-input': isHouseNameOrNumberInvalid() || addressForm.controls.houseNumber.invalid }"
        [isValidationOn]="!isHouseNameOrNumberInvalid() && addressForm.controls.houseNumber.value"
        [appendContent]="true"
        [attr.data-qa-id]="'house-number-input'"
      ></lib-input>

      <ng-container *ngTemplateOutlet="houseNumber; context: { control: addressForm.controls.houseNumber }"></ng-container>
    </div>

    <div
      class="pb-xs"
      [attr.data-qa-id]="'street-input'"
    >
      <lib-input
        labelId="street-name"
        [labelText]="'Street name'"
        [control]="addressForm.controls.streetName"
        class="display-block"
        [isValidationOn]="true"
      ></lib-input>
      <ng-container *ngTemplateOutlet="generalError; context: { control: addressForm.controls.streetName, text: 'Street name' }"></ng-container>
    </div>

    <div
      class="pb-xs"
      [attr.data-qa-id]="'town-input'"
    >
      <lib-input
        labelId="town"
        [labelText]="'Town / City'"
        [control]="addressForm.controls.town"
        class="display-block"
        [isValidationOn]="true"
      ></lib-input>
      <ng-container *ngTemplateOutlet="generalError; context: { control: addressForm.controls.town, text: 'Town' }"></ng-container>
    </div>

    <lib-input
      labelId="postcode"
      [labelText]="'Postcode'"
      [control]="addressForm.controls.postcode"
      class="display-block mb-xs"
      [isValidationOn]="true"
      errorMessage="Please provide a valid Postcode."
    ></lib-input>

    <ng-container *ngIf="showCountry">
      <lib-dropdown
        *ngIf="country"
        title="Country"
        placeholder="- Select Country -"
        [list]="country"
        [control]="addressForm?.get('country')"
        (onChange)="onDropdownChange('country')"
      >
      </lib-dropdown>

      <div
        class="invalid-field"
        *ngIf="!addressForm?.get('country').value && addressForm?.get('country').touched"
        [attr.data-qa-id]="'country-error'"
      >
        Please select a country.
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Flat error messages -->
<ng-template
  #flat
  let-control="control"
>
  <p
    *ngIf="control.errors?.maxlength && control.touched && control.invalid; else invalidFlat"
    class="invalid-field ng-star-inserted"
  >
    Flat can be no longer than 30 characters.
  </p>

  <ng-template #invalidFlat>
    <p
      *ngIf="control.errors?.pattern && control.touched && control.invalid"
      class="invalid-field ng-star-inserted"
    >
      Please provide a valid Flat.
    </p>
  </ng-template>
</ng-template>

<!-- StreetName error messages -->
<ng-template
  #generalError
  let-control="control"
  let-text="text"
>
  <p
    *ngIf="(control.errors?.pattern || control.errors?.required) && control.touched && control.invalid; else one"
    class="invalid-field ng-star-inserted"
  >
    Please provide a valid {{ text }}.
  </p>

  <ng-template #one>
    <p
      *ngIf="control.errors?.maxlength && control.touched && control.invalid; else two"
      class="invalid-field ng-star-inserted"
    >
      {{ text }} can be no longer than 50 characters.
    </p>
  </ng-template>

  <ng-template #two>
    <p
      *ngIf="control.errors?.minlength && control.touched && control.invalid"
      class="invalid-field ng-star-inserted"
    >
      {{ text }} is required and must have a minimum length of 2 characters.
    </p>
  </ng-template>
</ng-template>

<!-- House name error messages -->
<ng-template
  #houseName
  let-control="control"
>
  <p
    *ngIf="control.errors?.maxlength && control.touched && control.invalid; else one"
    class="invalid-field ng-star-inserted"
  >
    House name can be no longer than 50 characters.
  </p>

  <ng-template #one>
    <p
      *ngIf="control.errors?.minlength && control.touched && control.invalid; else two"
      class="invalid-field ng-star-inserted"
    >
      House name must have a minimum length of 2 characters.
    </p>
  </ng-template>
  <ng-template #two>
    <p
      *ngIf="control.errors?.pattern && control.touched && control.invalid"
      class="invalid-field ng-star-inserted"
    >
      Please provide a valid House name.
    </p>
  </ng-template>
</ng-template>

<ng-template
  #houseNumber
  let-control="control"
>
  <p
    *ngIf="isHouseNameOrNumberInvalid(); else one"
    class="invalid-field ng-star-inserted"
    [attr.data-qa-id]="'invalid-house-name-number'"
  >
    Please enter a valid House name or number.
  </p>

  <ng-template #one>
    <p
      *ngIf="control.errors?.pattern && control.touched && control.invalid; else two"
      class="invalid-field ng-star-inserted"
    >
      Please provide a valid House number.
    </p>
  </ng-template>

  <ng-template #two>
    <p
      *ngIf="control.errors?.maxlength && control.touched && control.invalid"
      class="invalid-field ng-star-inserted"
    >
      House number can be no longer than 10 characters.
    </p>
  </ng-template>
</ng-template>
