import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from './lib-app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private appInsights: AppInsightsService) {
    super();
  }

  handleError(error): void {
    // Log errors in app insights
    this.appInsights.logException(error);

    // Default error logging in console
    super.handleError(error);
  }
}
