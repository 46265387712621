import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-close-icon',
  templateUrl: './lib-close-icon.component.html',
  styles: [],
})
export class LibCloseIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
