import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-scrollable-list',
  templateUrl: './lib-scrollable-list.component.html',
  styleUrls: ['./lib-scrollable-list.component.scss'],
})
export class LibScrollableListComponent {
  @Input() list: any[];
  @Input() inputLabel: string;
  @Output() itemClicked = new EventEmitter();

  selectedItem: number;

  listItemClicked(index: number) {
    this.selectedItem = index;
    this.itemClicked.emit(index.toString());
  }
}
