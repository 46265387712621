import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LibModalService } from '@experian-digital/affordability-library';
import { CookieService } from '@experian-digital/affordability-library/src/lib/shared/services/lib-cookie.service';
import { APP_ROUTES } from '@passport/src/app/affordability/routes';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-cookie-consent-panel',
  templateUrl: './lib-cookie-consent-panel.component.html',
  styleUrls: ['./lib-cookie-consent-panel.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: '{{translateType}}(-100%)' }),
        animate('400ms ease-in', style({ transform: '{{translateType}}(0%)' })),
      ]),
      transition(':leave', [animate('400ms ease-in', style({ transform: '{{translateType}}(-100%)' }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LibCookieConsentPanelComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() cookieName: string;
  @Output() cookieConsentChanged: EventEmitter<boolean> = new EventEmitter();

  screenWidth = window.innerWidth;

  ngOnInit(): void {
    if (!this.router.url.includes(APP_ROUTES.cookiePolicy)) {
      CookieService.setCookiePanelVisibility(this.cookieName);
    }
  }

  getAnimationTransformType(): string {
    return this.screenWidth <= 768 ? 'translateY' : 'translateX';
  }

  isCookiePanelVisible(): BehaviorSubject<boolean> {
    return CookieService.isCookiePanelVisible;
  }

  saveAndClose(consent: boolean): void {
    this.cookieConsentChanged.emit(consent);
    LibModalService.isOverlayVisible.next(false);
  }
}
