import { UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';

export class DateValidators {
  public static validate(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
    const dayControl = formGroup.get('day');
    const monthControl = formGroup.get('month');
    const yearControl = formGroup.get('year');

    if (!dayControl?.value || !monthControl?.value || !yearControl?.value) {
      return { invalidDate: true };
    }

    if (dayControl.value.length === 2 && monthControl.value.length === 2 && yearControl.value.length === 4) {
      const date = moment(
        yearControl.value + '-' + monthControl.value + '-' + dayControl.value,
        'YYYY-M-DD',
        true,
      ).utc();
      return !date.isValid() ? { invalidDate: true } : null;
    }

    return { invalidDate: false };
  }

  public static checkChosenDateInFuture(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
    const dayControl = formGroup.get('day');
    const monthControl = formGroup.get('month');
    const yearControl = formGroup.get('year');

    if (dayControl.valid && monthControl.valid && yearControl.valid) {
      const chosenDate = new Date(`${yearControl.value}-${monthControl.value}-${dayControl.value}`).getTime();
      const todaysDate = new Date().getTime();
      return chosenDate > todaysDate ? { chosenDateInFuture: true } : null;
    }

    return null;
  }

  public static checkChosenDateIsAfter(date: Moment): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const dayControl = formGroup.get('day');
      const monthControl = formGroup.get('month');
      const yearControl = formGroup.get('year');
      if (dayControl.valid && monthControl.valid && yearControl.valid) {
        const inputDate = moment(
          new Date(yearControl.value + '-' + monthControl.value + '-' + dayControl.value),
          'YYYY-MM-DD',
        ).utc();
        return inputDate.isAfter(date) && inputDate.isBefore(moment()) ? { chosenDateIsAfter: true } : null;
      }
    };
  }

  public static checkChosenDateIsBefore(date: Moment): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const dayControl = formGroup.get('day');
      const monthControl = formGroup.get('month');
      const yearControl = formGroup.get('year');
      if (dayControl.valid && monthControl.valid && yearControl.valid) {
        const inputDate = moment(
          new Date(yearControl.value + '-' + monthControl.value + '-' + dayControl.value),
          'YYYY-MM-DD',
        ).utc();
        return inputDate.isBefore(date) && inputDate.isBefore(moment()) ? { chosenDateIsBefore: true } : null;
      }
    };
  }

  public static checkOtherDates(index: number, formArray: UntypedFormArray): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const currenDateOfMove =
        formArray?.at(index)?.get('year')?.value +
        '-' +
        formArray?.at(index)?.get('month')?.value +
        '-' +
        formArray?.at(index)?.get('day')?.value;
      let isCurrentAfterPreviousDate = false;
      for (let i = 0; i < index; i++) {
        const prevDateOfMove =
          formArray?.at(index - 1)?.get('year').value +
          '-' +
          formArray?.at(index - 1)?.get('month').value +
          '-' +
          formArray?.at(index - 1)?.get('day').value;

        isCurrentAfterPreviousDate = moment(new Date(currenDateOfMove), 'YYYY-DD-MM', true)
          .utc()
          .isAfter(new Date(prevDateOfMove), 'day');
        if (isCurrentAfterPreviousDate) {
          break;
        }
      }
      return isCurrentAfterPreviousDate ? { isCurrentAfterPreviousDate: true } : null;
    };
  }

  public static checkCurrentAfterNextDate(index: number, formArray: UntypedFormArray): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const currenDateOfMove =
        formArray?.at(index)?.get('year')?.value +
        '-' +
        formArray?.at(index)?.get('month')?.value +
        '-' +
        formArray?.at(index)?.get('day')?.value;
      let isCurrentAfterNextDate = false;
      for (let i = 0; i < index; i++) {
        const nextDateOfMove =
          formArray?.at(index + 1)?.get('year').value +
          '-' +
          formArray?.at(index + 1)?.get('month').value +
          '-' +
          formArray?.at(index + 1)?.get('day').value;

        isCurrentAfterNextDate = moment(new Date(currenDateOfMove), 'YYYY-DD-MM', true)
          .utc()
          .isBefore(new Date(nextDateOfMove), 'day');
        if (isCurrentAfterNextDate) {
          break;
        }
      }
      return isCurrentAfterNextDate ? { isCurrentAfterNextDate: true } : null;
    };
  }

  public static checkChosenDateInFutureArray(index: number, formArray: UntypedFormArray): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const dayControl = formArray?.at(index)?.get('day');
      const monthControl = formArray?.at(index)?.get('month');
      const yearControl = formArray?.at(index)?.get('year');

      if (dayControl?.valid && monthControl?.valid && yearControl?.valid) {
        const chosenDate = new Date(`${yearControl.value}-${monthControl.value}-${dayControl.value}`).getTime();
        const todaysDate = new Date().getTime();
        return chosenDate > todaysDate ? { chosenDateInFutureArray: true } : null;
      }

      formArray?.at(index)?.get('month')?.setErrors(null);
      formArray?.at(index)?.get('year')?.setErrors(null);
      return null;
    };
  }

  public static checkMoveInDateIsValid(index: number, formArray: UntypedFormArray): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      if (formArray?.at(index)) {
        const isFormGroupPresent =
          formArray?.at(index) instanceof UntypedFormGroup && Object.keys(formArray?.at(index).value).length > 1
            ? true
            : false;
        if (!isFormGroupPresent) {
          return null;
        }

        const dayControl = formArray.at(index)?.get('day');
        const monthControl = formArray.at(index)?.get('month');
        const yearControl = formArray.at(index)?.get('year');

        if (monthControl?.value?.length == 2 && monthControl?.touched) {
          formArray?.at(index)?.get('month')?.setErrors(null);
        } else {
          formArray?.at(index)?.get('month')?.setErrors({ invalidMoveInDate: true });
        }

        if (yearControl?.value?.toString()?.length == 4 && yearControl?.touched) {
          formArray?.at(index)?.get('year')?.setErrors(null);
        } else {
          formArray?.at(index)?.get('year')?.setErrors({ invalidMoveInDate: true });
        }

        if (
          dayControl?.value?.length == 2 &&
          monthControl?.value?.length == 2 &&
          yearControl?.value?.toString()?.length == 4
        ) {
          const date = moment(
            yearControl.value + '-' + monthControl.value + '-' + dayControl.value,
            'YYYY-M-DD',
            true,
          ).utc();
          if (!date.isValid()) {
            return { invalidMoveInDate: true };
          } else {
            formArray?.at(index)?.get('month')?.setErrors(null);
            formArray?.at(index)?.get('year')?.setErrors(null);
            return null;
          }
        }
      }

      return null;
    };
  }

  public static checkPastThreeYearsOrLastThreeAddress(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
    const formArray = formGroup?.controls?.address as UntypedFormArray;

    if (formArray?.valid) {
      let datesArray = [];
      for (let i = 0; i < formArray.controls.length; i++) {
        if (formArray?.at(i)?.get('year')?.valid && formArray?.at(i)?.get('month')?.valid) {
          let moveInDate = `${formArray?.at(i)?.get('year')?.value}-${formArray?.at(i)?.get('month')?.value}-${
            formArray?.at(i)?.get('day')?.value
          }`;
          let formattedMoveInDate = moment(moveInDate, 'YYYY-M-DD', true).utc();
          if (!formattedMoveInDate.isValid()) {
            return { notPastThreeYearsOrLastThreeAddress: true };
          } else {
            datesArray.push(moveInDate);
          }
        }
      }

      const isLastThreeAddress = datesArray.length === 3;

      const currentDate = new Date();
      const threeYearsAgo = new Date(currentDate);
      threeYearsAgo.setFullYear(currentDate.getFullYear() - 3);

      const lastDate = new Date(datesArray[datesArray.length - 1]);

      const isPastThreeYearsAddress = lastDate <= threeYearsAgo;

      if (!isLastThreeAddress && !isPastThreeYearsAddress && datesArray.length > 0) {
        return { notPastThreeYearsOrLastThreeAddress: true };
      }
    }

    return null;
  }
}
