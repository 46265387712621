import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibButtonModule } from '../lib-button/lib-button.module';
import { LibTextInputModule } from '../lib-input/lib-input.module';
import { LibTextWithButtonComponent } from './lib-text-with-button.component';
@NgModule({
  declarations: [LibTextWithButtonComponent],
  imports: [CommonModule, LibTextInputModule, LibButtonModule],
  exports: [LibTextWithButtonComponent],
})
export class LibTextWithButtonModule {}
