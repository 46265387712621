import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapitaliseFirstLetterPipe } from './capitalise-first-letter.pipe';
import { CurrencySymbolPipe } from './currency.pipe';
import { DateSuffixPipe } from './date-suffix.pipe';
import { ReplaceUnderscorePipe } from './replace-underscore.pipe';

const PIPES = [CapitaliseFirstLetterPipe, DateSuffixPipe, ReplaceUnderscorePipe, CurrencySymbolPipe];

@NgModule({
  declarations: PIPES,
  imports: [CommonModule],
  exports: PIPES,
})
export class LibPipesModule {}
