<div
    id="text-with-button"
    [ngClass]="{'align-items-center': fieldTouchedAndInvalid && errorMessage && inputLabel, 'align-items-start': fieldTouchedAndInvalid && errorMessage && !inputLabel, 'align-items-end':  inputLabel && !fieldTouchedAndInvalid}"
>

    <lib-input
        class="flex-grow"
        [labelId]="inputLabel"
        [labelText]="inputLabel"
        [control]="control"
        [errorMessage]="errorMessage"
        [isValidationOn]="true"
        (keyup.enter)="buttonClicked($event)"
        [attr.data-qa-id]="'lib-text-button-input'"
    ></lib-input>

    <lib-button
        styleName="button--secondary button--outline"
        [buttonText]="buttonLabel"
        (clickEvent)="buttonClicked($event)"
        [attr.data-qa-id]="'lib-text-button-button'"
    ></lib-button>

</div>
