import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LibDownArrowIconModule } from './../icons/lib-down-arrow-icon/lib-down-arrow-icon.module';
import { LibDropdownComponent } from './lib-dropdown.component';

@NgModule({
  declarations: [LibDropdownComponent],
  imports: [CommonModule, ReactiveFormsModule, LibDownArrowIconModule],
  exports: [LibDropdownComponent],
})
export class LibDropdownModule {}
