import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-info-icon',
  templateUrl: './lib-info-icon.component.html',
  styles: [],
})
export class LibInfoIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
