<div
  class="dropdown flex align-items-center justify-content-center"
  [ngClass]="[!!className ? className : '', isDisabled ? 'disabled' : '']"
  (mouseenter)="this.isDropDownActive = true"
  (mouseleave)="this.isDropDownActive = false"
  (keydown.enter)="toggleActiveWithKey($event)"
  (keydown.space)="toggleActiveWithKey($event)"
  tabindex=0
>

  <div class="flex align-items-center justify-content-between dropdown__title c-pointer">
    <span
      class="truncate"
      *ngIf="title"
      [ngStyle]="styleOptions.menuHeadingStyles"
    >{{title}}</span>
    <span class="pl-xxs dropdown__title__icon">
      <lib-down-arrow-icon
        [ngClass]="'icon-'+styleOptions.caretStyles.color"
        class="icon-xxxs"
      ></lib-down-arrow-icon>
    </span>
  </div>
  <div
    *ngIf="isDropDownActive"
    class="dropdown__content"
    [class.dropdown__content-right]="dropdownPosition === 'right'"
    role="menu"
  >
    <p
      *ngFor="let item of list; let i = index;"
      class="dropdown__content__item flex align-items-center justify-content-between"
      [class.dropdown__disabled]="item?.disabled"
      [class.dropdown__content__item--selected]="item.isSelected"
      [ngStyle]="getCombinedStyles(item)"
      tabindex=0
      (click)="onSelect(item)"
      (keydown.enter)="onSelect(item, $event)"
      (keydown.space)="onSelect(item, $event)"
      (focus)="onFocus(i)"
      (blur)="onBlur(i)"
      role="menuitem"
      aria-disabled="{{item?.disabled}}"
    >{{item.title}}
      <span class="dropdown__content__icon-wrapper ml-xxs">
        <lib-fill-tick-round-icon
          *ngIf="isSelectedTickVisible && item.isSelected"
          class="icon-green icon-xxs flex"
          [attr.data-qa-id]="'green-tick-icon'"
        ></lib-fill-tick-round-icon>
      </span>
    </p>
  </div>
</div>
