import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibProgressBarComponent } from './lib-progress-bar.component';

@NgModule({
  declarations: [LibProgressBarComponent],
  imports: [
    CommonModule
  ],
  exports: [LibProgressBarComponent]
})
export class LibProgressBarModule { }
