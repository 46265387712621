import { Injectable, Injector } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AppInsightsService, AuthService } from '@experian-digital/affordability-library';
import { filter, take } from 'rxjs/operators';
import { ApplicationInsightsEvents } from './../models/enums/appinsights-events.enum';

const ONE_MINUTE = 60000;
export const TIMEOUT_AFTER_INACTIVITY = ONE_MINUTE * 15;

@Injectable()
export class TimeoutService {
  timerInstance = 0;

  constructor(private injector: Injector) {
    this.resetTimerOnRouteChange();
  }

  initTimer(): void {
    clearTimeout(this.timerInstance);

    this.timerInstance = window.setTimeout(() => {
      clearTimeout(this.timerInstance);
      this.beforeunloadListener();
    }, TIMEOUT_AFTER_INACTIVITY);
  }

  resetTimerOnRouteChange(): void {
    this.injector
      .get(Router)
      .events.pipe(
        take(1),
        filter((event) => event instanceof NavigationStart),
      )
      .subscribe(() => {
        clearTimeout(this.timerInstance);
      });
  }

  beforeunloadListener(): void {
    AppInsightsService.logEvent(ApplicationInsightsEvents.TimeoutLogout, { currentUrl: window.location.pathname });
    this.injector.get(AuthService).logOut();
  }
}
