<div class="spinner">
  <div
    [ngStyle]="getStyle()"
    class="spinner__spinner-container"
    [ngClass]="{ 'spinner__spinner-container--small': isSmall }"
  >
    <div
      tabindex="0"
      role="alert"
      aria-busy="true"
      aria-label="Loading spinner"
      class="spinner__common spinner__visible"
    >
      <svg viewBox="0 0 128 128" aria-valuemin="0" style="width: 100%; height: 100%">
        <title>Loading spinner</title>
        <desc>A progress circle animation that displays while something is loading.</desc>
        <g
          class="spinner__donut___spin"
          fill="transparent"
          stroke="currentColor"
          stroke-dasharray="12.534954687823275 345.6066078214131"
          stroke-width="10"
        >
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="202.35" style="opacity: 0.1" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="189.815" style="opacity: 0.2" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="177.28" style="opacity: 0.3" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="164.745" style="opacity: 0.4" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="152.21" style="opacity: 0.5" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="139.675" style="opacity: 0.6" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="127.14" style="opacity: 0.7" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="114.605" style="opacity: 0.8" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="102.07" style="opacity: 0.9" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="89.535" style="opacity: 1" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="77" style="opacity: 0.9" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="64.465" style="opacity: 0.8" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="51.931" style="opacity: 0.7" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="39.396" style="opacity: 0.6" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="26.861" style="opacity: 0.5" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="14.326" style="opacity: 0.4" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="1.791" style="opacity: 0.3" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="-10.744" style="opacity: 0.2" />
          <circle aria-hidden="true" cx="64" cy="64" r="57" stroke-dashoffset="-23.279" style="opacity: 0.1" />
        </g>
        <circle
          aria-hidden="true"
          fill="transparent"
          cx="64"
          cy="64"
          r="57"
          stroke="currentColor"
          stroke-dasharray="358.1415625092364 0"
          stroke-width="10"
          stroke-dashoffset="89.535"
          style="opacity: 0.1"
        />
      </svg>
    </div>
  </div>
  <div class="spinner__text-container">
    <ng-content></ng-content>
  </div>
</div>
