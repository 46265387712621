import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibExperianLogoSvgComponent } from './lib-experian-logo-svg.component';

@NgModule({
  declarations: [LibExperianLogoSvgComponent],
  imports: [CommonModule],
  exports: [LibExperianLogoSvgComponent],
})
export class LibExperianLogoSvgModule {}
