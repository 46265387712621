import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-tick-icon',
  templateUrl: './lib-tick-icon.component.html',
  styleUrls: ['lib-tick-icon.component.scss'],
})
export class LibTickIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
