<svg
  xmlns="http://www.w3.org/2000/svg"
  width="11"
  height="20"
  viewBox="0 0 11 20"
  aria-labelledby="chevron-more-icon-title chevron-more-icon-desc"
  role="img"
>
  <title id="chevron-more-icon-title">Chevron more icon</title>
  <desc id="chevron-more-icon-desc">Click to view more</desc>
  <path
    fill="#ba2f7d"
    fill-rule="evenodd"
    stroke="#ba2f7d"
    class="fill stroke"
    d="m 8.8239961,10 -7.681,-8.12 a -0.537,0.537 0 0 1 0,-0.73 -0.469,0.469 0 0 1 0.689,0 l 8.025,8.488 a -0.534,0.534 0 0 1 0,0.73 l -8.025,8.482 c -0.19,0.201 -0.499,0.201 -0.69,0 a -0.537,0.537 0 0 1 0,-0.73 l 7.683,-8.12 z"
    id="path61"
  />
</svg>
