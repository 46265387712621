import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibChevronIconModule } from '../icons/lib-chevron-icon/lib-chevron-icon.module';
import { LibPanelModule } from '../lib-panel/lib-panel.module';
import { LibSpinnerV2Module } from '../v2/lib-spinner-v2/lib-spinner-v2.module';
import { LibSpinnerModule } from './../lib-spinner/lib-spinner.module';
import { LibAccordionComponent } from './lib-accordion.component';

@NgModule({
  imports: [CommonModule, LibPanelModule, LibChevronIconModule, LibSpinnerModule, LibSpinnerV2Module],
  declarations: [LibAccordionComponent],
  exports: [LibAccordionComponent],
})
export class LibAccordionModule {}
