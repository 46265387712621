import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, URLUtils } from '@experian-digital/affordability-library';
import { APP_ROUTES } from '@portal-app/advisor/routes';
import { ForbiddenErrorCodes } from '@portal-shared/models/enums/forbidden-error-codes.enum';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationConfigService } from './config.service';

@Injectable()
export class SanitizerHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Sanitize url
    if (
      request.url.includes('/api/') ||
      request.url.toLowerCase().includes(ApplicationConfigService.config.digitalIdentityUrl)
    ) {
      const sanitizedUrl = URLUtils.sanitizeUrl(request.url);
      // Set Bearer token
      let sanitizedHeaders: HttpHeaders = request.headers;
      sanitizedHeaders = sanitizedHeaders
        .set('Authorization', `Bearer ${this.authService.getAccessToken() || ' '}`)
        .set('DepartmentShortName', localStorage.getItem('departmentName') || '');

      if (request.url.toLowerCase().includes(ApplicationConfigService.config.employmentVerificationUrl)) {
        sanitizedHeaders = sanitizedHeaders.set('OrganisationId', localStorage.getItem('organisationId') || '');
      }

      // Sanitize headers
      sanitizedHeaders?.keys().forEach((key) => {
        sanitizedHeaders = sanitizedHeaders.set(key, URLUtils.sanitizeUrl(sanitizedHeaders.get(key)));
      });

      request = request.clone({
        url: sanitizedUrl,
        headers: sanitizedHeaders,
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 403) {
          if (err.error.code === ForbiddenErrorCodes.UnauthorisedIP) {
            this.router.navigateByUrl(APP_ROUTES.unauthorised);
            return;
          } else if (err.error.code === ForbiddenErrorCodes.DepartmentMode) {
            this.router.navigateByUrl(APP_ROUTES.home);
            return;
          }
        }
        return throwError(err);
      }),
    );
  }
}
