import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibWarningIconModule } from '../icons/lib-warning-icon/lib-warning-icon.module';
import { LibFlashMessageComponent } from './lib-flash-message.component';

@NgModule({
  imports: [CommonModule, LibWarningIconModule],
  declarations: [LibFlashMessageComponent],
  exports: [LibFlashMessageComponent],
})
export class LibFlashMessageModule {}
