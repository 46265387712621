import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LibChevronIconModule } from '../icons/lib-chevron-icon/lib-chevron-icon.module';
import { LibFillCrossRoundIconModule } from '../icons/lib-fill-cross-round-icon/lib-fill-cross-round-icon.module';
import { LibFillTickRoundIconModule } from '../icons/lib-fill-tick-round-icon/lib-fill-tick-round-icon.module';
import { LibPlusIconModule } from '../icons/lib-plus-icon/lib-plus-icon.module';
import { LibAddressPickerModule } from '../lib-address-picker/lib-address-picker.module';
import { LibButtonModule } from '../lib-button/lib-button.module';
import { LibDateInputModule } from '../lib-date-input/lib-date-input.module';
import { LibDropdownModule } from '../lib-dropdown/lib-dropdown.module';
import { LibTextInputModule } from '../lib-input/lib-input.module';
import { LibMultipleAddressesComponent } from './lib-multiple-addresses.component';

@NgModule({
  declarations: [LibMultipleAddressesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LibFillTickRoundIconModule,
    LibFillCrossRoundIconModule,
    LibAddressPickerModule,
    LibTextInputModule,
    LibDateInputModule,
    LibChevronIconModule,
    LibButtonModule,
    LibPlusIconModule,
    LibDropdownModule,
  ],
  exports: [LibMultipleAddressesComponent],
})
export class LibMultipleAddressesModule {}
