<svg
  width="30px"
  height="30px"
  viewBox="0 0 30 30"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-labelledby="filled-cross-round-icon-title filled-cross-round-icon-desc"
  role="img"
>
  <title id="filled-cross-round-icon-title">Cross icon</title>
  <desc id="filled-cross-round-icon-desc">This is a cross icon.</desc>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="15_AML-checks"
      transform="translate(-921.000000, -648.000000)"
    >
      <g
        id="Account-Copy"
        transform="translate(118.000000, 628.000000)"
      >
        <g
          id="Tick"
          transform="translate(803.000000, 20.000000)"
        >
          <path
            d="M15,30 C23.2842712,30 30,23.2842712 30,15 C30,6.71572875 23.2842712,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842712 6.71572875,30 15,30 Z"
            id="Oval-Copy"
            fill="#BB0048"
            class="fill"
          ></path>
          <polygon
            id="+"
            stroke="#FFFFFF"
            fill="#FFFFFF"
            fill-rule="nonzero"
            transform="translate(14.860330, 14.818438) rotate(46.000000) translate(-14.860330, -14.818438) "
            points="15.5004446 23.8760555 15.5004446 15.2798641 23.7206595 15.2798641 23.7206595 14.3741024 15.5004446 14.3741024 15.5004446 5.76082116 14.5605032 5.76082116 14.5605032 14.3741024 6 14.4623132 6 15.3680749 14.5605032 15.2798641 14.5605032 23.8760555"
          ></polygon>
        </g>
      </g>
    </g>
  </g>
</svg>
