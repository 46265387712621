import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LibAccordionItemComponent } from './lib-accordion-item.component';

@Injectable({
  providedIn: 'root',
})
export class LibAccordionItemService {
  openAccordion: Subject<LibAccordionItemComponent> = new Subject<LibAccordionItemComponent>();
}
