import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-fill-cross-round-icon',
  templateUrl: './lib-fill-cross-round-icon.component.html',
})
export class LibFillCrossRoundIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
