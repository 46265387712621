import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LibChevronIconModule } from '../icons/lib-chevron-icon/lib-chevron-icon.module';
import { LibFillTickRoundIconModule } from '../icons/lib-fill-tick-round-icon/lib-fill-tick-round-icon.module';
import { LibPanelModule } from '../lib-panel/lib-panel.module';
import { LibDownArrowIconModule } from './../icons/lib-down-arrow-icon/lib-down-arrow-icon.module';
import { LibDropdownMenuComponent } from './lib-dropdown-menu.component';

@NgModule({
  imports: [
    CommonModule,
    LibPanelModule,
    LibChevronIconModule,
    LibDownArrowIconModule,
    RouterModule,
    LibFillTickRoundIconModule,
  ],
  declarations: [LibDropdownMenuComponent],
  exports: [LibDropdownMenuComponent],
})
export class LibDropdownMenuModule {}
