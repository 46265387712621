/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationAuthGuard } from '@experian-digital/affordability-library';

import { DepartmentResolver } from '@portal-shared/guards/department.resolver';
import { SelectDepartmentGuard } from '@portal-shared/guards/select-department.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ApplicationAuthGuard],
    loadChildren: () => import('./advisor/advisor.module').then((module) => module.AdvisorModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [DepartmentResolver, SelectDepartmentGuard],
})
export class AppRoutingModule {}
