import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibCloseIconModule } from '../icons/lib-close-icon/lib-close-icon.module';
import { LibModalComponent } from './lib-modal.component';

@NgModule({
  declarations: [LibModalComponent],
  imports: [CommonModule, LibCloseIconModule],
  exports: [LibModalComponent],
})
export class LibModalModule {}
