<div
  *ngIf="title.length > 0"
  class="pb-xs"
>
  <label [ngClass]="{'invalid-field': requiredFieldValidationError || errorMessage}">{{title}}</label>
</div>
<div
  [attr.data-qa-id]="'dropdown-box'"
  class="dropdown-container"
  [ngClass]="{'invalid-control': requiredFieldValidationError || errorMessage}"
>
  <!-- Dropdown input -->
  <div class="dropdown-input-container flex pb-xxxs">
    <input
      type="text"
      [(ngModel)]="inputText"
      [formControl]="control"
      [spellcheck]="spellcheck"
      [placeholder]="placeholder"
      (keyup)="searchQueryOnDataSource($event)"
    />
    <div
      *ngIf="this.inputText?.length > 0 else search"
      class="close-icon c-pointer"
    >
      <lib-close-icon
        class="icon-medium-pink icon-xxs c-pointer"
        (click)="resetForm()"
        title="Clear"
      ></lib-close-icon>
    </div>
    <ng-template #search>
      <div class="search-icon c-pointer">
        <lib-search-icon class="icon-medium-pink icon-xxs c-pointer"></lib-search-icon>
      </div>
    </ng-template>
    <!-- Dropdown list -->
    <div
      class="dropdown-list scrollable-list"
      *ngIf="isDropdownOpen"
      [attr.data-qa-id]="'dropdown-list'"
    >
      <!-- Placeholder -->
      <p
        *ngIf="!placeholder"
        class="list-item px-sm py-xxs placeholder"
        [class.selected]="!selectedItem"
        [attr.data-qa-id]="'placeholder'"
      >
      </p>

      <!-- List -->
      <p
        class="list-item px-sm py-xxs c-pointer"
        [class.selected]="isSelected(option.title)"
        [title]="option.title"
        *ngFor="let option of list ; let i = index"
        [attr.data-qa-id]="'list-item-' + i"
        (click)="onSelect(option)"
      >
        <span class="truncate-xxs font-weight-bold">
          {{option.title}}
        </span>
        <span
          class="color-medium-grey"
          *ngIf="option.data"
        >
          {{option.data}}
        </span>
      </p>
    </div>
    <div
      class="dropdown-list scrollable-list"
      *ngIf="isNoResult && !errorMessage"
      [attr.data-qa-id]="'noresult-list'"
    >
      <!-- Placeholder -->
      <p
        class="list-item px-sm py-xxs placeholder"
        [attr.data-qa-id]="'placeholder'"
      >
        <span class="truncate-xxs">
          This employer is not listed
        </span>
      </p>
    </div>
  </div>
  <div>
    <a
      *ngIf="displayCannotFindEmployer"
      class="mt-xs pb-md c-pointer small"
      (click)="onCannotFindEmployerClick()"
      [attr.data-qa-id]="'cannot-find-employer'"
    >I can't find my employer</a>
  </div>
  <div
    *ngIf="requiredFieldValidationError"
    class="invalid-field"
    [attr.data-qa-id]="'provide-valid-input-error'"
  >{{validationMessage}}</div>
  <div
    *ngIf="errorMessage"
    class="invalid-field mt-xs"
    [attr.data-qa-id]="'something-wrong-error'"
  >{{errorMessage}}</div>
</div>
