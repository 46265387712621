<div class="text-align-center pt-md">
  <h3>{{error?.heading}}</h3>

  <p
    *ngIf="error?.subText as subText"
    class="mb-sm color-dark-blue"
  >{{subText}}</p>

  <a
    href="#"
    (click)="callRetry($event)"
  >{{error?.linkText}}</a>

  <div
    class="mt-sm"
    *ngIf="error?.errorDetails"
  >
    <p
      class="color-grey xsmall mr-xs"
      [attr.data-qa-id]="'problem-reference'"
    ><span class="font-weight-bold">Problem Reference:</span> {{error?.errorDetails?.correlationId}}</p>
    <p
      class="color-grey xsmall P-sm"
      [attr.data-qa-id]="'time'"
    ><span class="font-weight-bold">Time:</span> {{error?.errorDetails?.timeStamp}}</p>
  </div>
</div>
