<svg
  width="80px"
  height="80px"
  viewBox="0 0 80 80"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-labelledby="faq-icon-title faq-icon-desc"
  role="img"
>
  <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
  <title id="faq-icon-title">FAQ Icon</title>
  <desc id="faq-icon-desc">This is an FAQ icon.</desc>
  <defs>
    <rect
      id="path-1"
      x="0"
      y="0"
      width="80"
      height="80"
    ></rect>
  </defs>
  <g
    id="FAQ-Icon"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g>
      <mask
        id="b53efe85-986d-4dd4-a1c1-b4ea823a1ddd"
        fill="white"
        class="fill"
      >
        <use xlink:href="#path-1"></use>
      </mask>
      <g id="Mask"></g>
      <g mask="url(#b53efe85-986d-4dd4-a1c1-b4ea823a1ddd)">
        <g transform="translate(15.000000, 15.000000)">
          <g
            id="message-bubble"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M29.2448983,0.339051095 C41.6861861,2.45059686 50.8145238,13.7531205 49.9423894,26.1973812 C49.5358468,32.1909015 46.9762045,37.7272019 42.6746051,41.9529192 C43.7783784,43.5516479 44.7686579,44.5795652 47.3850611,46.6331165 C47.9074347,47.0322393 48.141376,47.6610338 47.966484,48.2875542 C47.8506529,48.9163601 47.3850611,49.4291771 46.8036382,49.6008772 C45.9315038,49.8862829 44.6528385,50 43.1992812,50 C40.1172745,50 36.222206,49.3154829 33.1993882,47.7747509 C29.1885003,49.1437828 24.884459,49.487183 20.6394902,48.7446714 C8.19820237,46.5751335 -0.813852999,35.2147296 0.0582813917,22.8863436 L0.0580488226,22.8858756 C0.523640674,15.9779152 4.01218986,9.53002895 9.53582396,5.24894298 C15.0570742,0.965630838 22.2678232,-0.802800156 29.2448983,0.339051095 Z M28.9053065,2.31192701 C22.4863974,1.26142386 15.8525083,2.88838037 10.772958,6.82902754 C5.69121468,10.7676266 2.48174942,16.699682 2.05361888,23.0554361 C1.25125524,34.3975512 9.54234618,44.8491228 20.988331,46.8450977 C24.5114016,47.461338 28.0786997,47.2645598 31.4523297,46.2954766 C31.818414,46.190318 33.3873158,45.6962927 33.7485352,45.572998 C36.5295275,46.9904715 38.4538574,47.5854492 41.6767578,47.798584 C42.9848633,47.8953803 44.2569835,47.8953803 45.0593471,47.632807 C45.2614716,47.4329834 45.2614716,47.2496338 44.7265625,46.8450977 C43.7122593,46.098999 43.465447,45.6977594 42.9848633,45.3305664 C41.8912354,44.2672119 40.9702148,43.1618652 40.0026855,41.8666992 C40.3352762,41.5399756 41.914499,39.9347223 42.2242271,39.5914327 C45.5999299,35.8499444 47.6044121,31.1522229 47.9469982,26.1015907 C48.7493619,14.6528708 40.3512912,4.25454911 28.9053065,2.31192701 Z"
              id="Combined-Shape"
              fill="#BA2F7D"
              class="fill"
            ></path>
          </g>
          <path
            d="M26.0273438,30.8984375 C26.0273438,28.7773438 26.6894531,27.1601562 28.0136719,26.046875 C29.6191406,24.5703125 30.7089844,23.2783203 31.2832031,22.1708984 C31.8574219,21.0634766 32.1445312,19.8769531 32.1445312,18.6113281 C32.1445312,16.5957031 31.5615234,14.9990234 30.3955078,13.8212891 C29.2294922,12.6435547 27.6386719,12.0546875 25.6230469,12.0546875 C23.6542969,12.0546875 22.03125,12.6318359 20.7539062,13.7861328 C19.4765625,14.9404297 18.8261719,16.4785156 18.8027344,18.4003906 L18.8027344,18.4003906 L20.8945312,18.4003906 C20.9179688,17.0292969 21.3603516,15.9423828 22.2216797,15.1396484 C23.0830078,14.3369141 24.2167969,13.9355469 25.6230469,13.9355469 C27.0175781,13.9355469 28.1044922,14.3544922 28.8837891,15.1923828 C29.6630859,16.0302734 30.0527344,17.1816406 30.0527344,18.6464844 C30.0527344,19.3730469 29.9619141,20.0205078 29.7802734,20.5888672 C29.5986328,21.1572266 29.3408203,21.6787109 29.0068359,22.1533203 C28.6728516,22.6279297 27.9257812,23.4365234 26.765625,24.5791016 C25.6054688,25.7216797 24.8496094,26.7148438 24.4980469,27.5585938 C24.1464844,28.4023438 23.9589844,29.515625 23.9355469,30.8984375 L23.9355469,30.8984375 L26.0273438,30.8984375 Z M25.0957031,38.140625 C25.5410156,38.140625 25.8955078,38.0087891 26.1591797,37.7451172 C26.4228516,37.4814453 26.5546875,37.15625 26.5546875,36.7695312 C26.5546875,36.3710938 26.4228516,36.0341797 26.1591797,35.7587891 C25.8955078,35.4833984 25.5410156,35.3457031 25.0957031,35.3457031 C24.6386719,35.3457031 24.2841797,35.4833984 24.0322266,35.7587891 C23.7802734,36.0341797 23.6542969,36.3710938 23.6542969,36.7695312 C23.6542969,37.15625 23.7802734,37.4814453 24.0322266,37.7451172 C24.2841797,38.0087891 24.6386719,38.140625 25.0957031,38.140625 Z"
            id="?"
            fill="#BA2F7D"
            fill-rule="nonzero"
            class="fill"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
