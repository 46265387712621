import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { STORAGE_KEYS } from '@experian-digital/affordability-library';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PortalRoles } from '../../enums/portal-roles.enum';

const LOCAL_RESPONSE: UserInfo = {
  info: {
    role: PortalRoles.Advisor,
    departmentShortName: 'd',
    customerId: '1,2,3,4,5,6',
  },
  sub: '',
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  private environment;
  private userInfo: UserInfo;
  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  stateUrl: string;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    @Inject('environment')
    environment,
  ) {
    this.environment = environment;
    this.isAuthenticated$ = this.environment.production ? this.isAuthenticatedSubject$.asObservable() : of(true);
    this.isDoneLoading$ = this.environment.production ? this.isDoneLoadingSubject$.asObservable() : of(true);
    this.oauthService.events.subscribe(() => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events.pipe(filter((e) => e.type === 'token_expires')).subscribe((e) => {
      this.logOut();
    });

    this.oauthService.events
      .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe((e) => this.login());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  runInitialLoginSequence(): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLoginCodeFlow())
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return this.oauthService.loadUserProfile();
        }
      })
      .then((userInfo: UserInfo) => {
        this.userInfo = userInfo;
        // .state will store the last visited route
        // so when we arrive back at portal it
        // navigates to where user left off
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          this.stateUrl = this.oauthService.state;
          if (this.stateUrl.startsWith('/') === false) {
            this.stateUrl = decodeURIComponent(this.stateUrl);
          }
          this.router.navigateByUrl(this.stateUrl);
        }
        this.isDoneLoadingSubject$.next(true);
      })
      .catch(() => {
        this.isDoneLoadingSubject$.next(true);
      });
  }

  login(targetUrl?: string): void {
    this.oauthService.initCodeFlow(targetUrl || this.router.url);
  }

  logOut(): void {
    localStorage.removeItem(STORAGE_KEYS.oktaLogin);
    this.oauthService.revokeTokenAndLogout();
  }

  getClaims(): UserInfo {
    return this.environment.production ? this.userInfo?.info : LOCAL_RESPONSE.info;
  }

  getUserInfo(): Promise<UserInfo> {
    return this.oauthService.loadUserProfile() as Promise<UserInfo>;
  }

  getRoleFromClaims(): string {
    return this.getClaims()?.role;
  }

  getDepartmentFromClaims(): string {
    return this.getClaims()?.departmentShortName;
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }
}
