<label>{{inputLabel}}</label>
<ul
    class="scrollable-list"
    [attr.data-qa-id]="'scrollable-list'"
>
    <li
        *ngFor="let item of list; let i = index"
        (click)="listItemClicked(i)"
        class="scrollable-list__item color-grey"
        [ngClass]="{'scrollable-list__item--selected': i === selectedItem, 'scrollable-list__item--no-scroll': list.length < 5}"
    ><span class="truncate">{{item}}</span></li>
</ul>
