import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AccountHelper } from '@portal-shared/helpers/account-helper';
import { Account } from '../../models/account.interface';

@Component({
  selector: 'lib-account-card',
  templateUrl: './lib-account-card.component.html',
  styleUrls: ['./lib-account-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LibAccountCardComponent {
  @Input() account: Account;
  @Input() showStatusAndUpdatedDate: boolean;
  @Output() accountClicked: EventEmitter<Account> = new EventEmitter();

  getAccountType(account: Account): string {
    return AccountHelper.getAccountType(account);
  }

  getBWAMessage(account: Account): string {
    return AccountHelper.getBWAMessage(account);
  }

  getBWAInfoMessage(account: Account): string {
    return AccountHelper.getBWAInfoMessage(account);
  }
}
