<div
  class="account-card c-pointer"
  [attr.data-qa-id]="'account-card'"
  (click)="accountClicked.emit(account)"
>
  <div
    title="{{ account?.bank }}"
    class="mb-sm"
  >
    <div class="account-details flex align-items-center">
      <img
        [src]="account?.bankImageUrl"
        [attr.data-qa-id]="'bank-logo'"
      />
      <div class="truncate flex-grow">
        <h3
          class="truncate color-grey m-no"
          [attr.data-qa-id]="'bank-name'"
        >{{ account?.bank }}</h3>
        <small
          class="flex flex-wrap"
          [attr.data-qa-id]="'account-details'"
        ><span class="mr-xxs">{{ account?.name }}</span><span>({{ account?.accountNumber || '-' }} - {{ account?.sortCode || '-' }})</span></small>
      </div>
      <lib-chevron-more-icon class="icon-link-blue icon-xxs flex-no-grow text-align-right"></lib-chevron-more-icon>
    </div>
  </div>

  <div class="font-weight-regular">
    <ng-container *ngIf="showStatusAndUpdatedDate">
      <div
        class="flex justify-content-between"
        [attr.data-qa-id]="'account-status'"
      >
        <p class="small">Account Status</p>
        <p
          class="small font-weight-bold"
          [attr.data-qa-id]="'application-create-date'"
          [class.color-green]="account?.isActive"
          [class.color-red]="!account?.isActive"
        >
          {{ account?.isActive ? 'Active' : 'Inactive' }}
        </p>
      </div>
      <div
        class="flex justify-content-between"
        [attr.data-qa-id]="'account-updated-date'"
      >
        <p class="small">Data Last Refreshed On</p>
        <p class="small">{{ (account.updatedDate | date: 'dd/MM/yyyy') || '-' }}</p>
      </div>

      <hr class="my-xs" />
    </ng-container>

    <div
      class="flex justify-content-between"
      [attr.data-qa-id]="'account-type'"
    >
      <p class="small">Account Type</p>
      <p class="small">
        {{ account ? getAccountType(account) : '' }}
      </p>
    </div>
    <div
      class="flex justify-content-between"
      [attr.data-qa-id]="'account-balance'"
    >
      <p class="small">Account Balance</p>
      <p class="small">
        {{ (account?.defaultBalance?.value | currency: account?.defaultBalance?.currencyCode:'symbol') || '-' }}
      </p>
    </div>
    <div
      class="flex justify-content-between"
      [attr.data-qa-id]="'overdraft-limit'"
    >
      <div class="flex align-items-center">
        <p class="small">Overdraft Limit</p>
        <lib-info-icon
          class="icon-theme no-title icon-xxxs ml-xxxs"
          [matTooltip]="'Overdraft limit will be displayed when banks provide this value. &lsquo; - &rsquo; will be displayed when we have not received this data from the bank.'"
          matTooltipPosition="right"
          matTooltipClass="default-tooltip"
        ></lib-info-icon>
      </div>
      <p class="small">
        {{ (account?.overdraft?.value | currency: account?.overdraft?.currencyCode:'symbol') || '-' }}
      </p>
    </div>

    <div
      class="flex justify-content-between"
      [attr.data-qa-id]="'bwa-result'"
    >
      <div class="flex align-items-center">
        <p class="small">Account Ownership Check</p>
        <lib-info-icon
          class="icon-theme no-title icon-xxxs ml-xxxs"
          [matTooltip]="getBWAInfoMessage(account)"
          matTooltipPosition="right"
          matTooltipClass="default-tooltip"
        ></lib-info-icon>
      </div>

      <p class="small">{{ getBWAMessage(account) }}</p>
    </div>
  </div>
</div>
