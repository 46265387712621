<!-- Label -->
<label
  [for]="labelId"
  [class.invalid-field]="fieldTouchedAndInvalid"
>{{labelText}}</label>


<!-- Field -->
<div
  [class.append-content]="appendContent || isValidationOn"
  [class.read-only]="readOnly"
  class="flex"
>
  <input
    [id]="labelId"
    [type]="type"
    [formControl]="control"
    [placeholder]="placeholder || ''"
    [attr.readonly]="readOnly"
    [spellcheck]="spellcheck"
    (blur)="inputBlur()"
  />

  <div class="append-content__container flex">
    <!-- Green tick icon -->
    <div
      class="append-content__element"
      *ngIf="showGreenTick"
    >
      <lib-fill-tick-round-icon class="icon-green icon-sm"></lib-fill-tick-round-icon>
    </div>

    <!-- Red tick icon -->
    <div
      class="append-content__element append-content__invalid-icon"
      [class.append-content__invalid-icon--show]="fieldTouchedAndInvalid"
    >
      <lib-fill-cross-round-icon class="icon-red icon-sm"></lib-fill-cross-round-icon>
    </div>

    <div class="append-content__element">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<!-- Error message -->
<span
  *ngIf="isInvalid"
  class="invalid-field"
>{{errorMessage}}</span>
<span
  class="xsmall description"
  *ngIf="!isInvalid && description"
>{{description}}</span>
