<div class="spinner">
  <div class="spinner__text-container">
    <ng-content></ng-content>
  </div>
  <div
    [ngStyle]="getStyle()"
    class="spinner__spinner-container"
    [ngClass]="{'spinner__spinner-container--small': isSmall}"
  >
    <svg
      viewBox="0 0 100 100"
      width="250"
      height="250"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>Loading spinner</title>
      <desc>A progress circle animation that displays while something is loading.</desc>
      <circle
        cx="50"
        cy="50"
        r="45"
        class="background-circle"
      />

      <circle
        cx="50"
        cy="50"
        r="45"
        class="dash"
      />
    </svg>
  </div>
</div>
