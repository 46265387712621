export const NUMBER_PATTERN: RegExp = /\-?\d*\.?\d{1,2}/;
export const STRING_PATTERN: RegExp = /^[a-zA-Z_ ]*$/;
export const RICH_STRING_PATTERN: RegExp = /^[a-zA-Z\s\/,'!&.-]+$/;
export const RICH_STRING_NUMBER_PATTERN: RegExp = /^[a-zA-Z0-9\s\/,'!&.-]+$/;
// tslint:disable-next-line: max-line-length
export const EMAIL_ADDRESS_PATTERN: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBER_STRING_HYPHEN: RegExp = /^[A-Za-z0-9-]+$/;
export const STRING_HYPHEN: RegExp = /^[A-Za-z- ]+$/;
export const STRING_WITH_HYPHEN_APOSTROPHE =
  /^[A-Za-z-ÁáČčĎďÉéĚěÍíŇňÓóŘřŠšŤťÚúŮůÝýŽžâêîôûàèùëïüéÇçäÄÖöÜüßÁáÉéÍíÓóÚúĂăÂâÎîȘșȚțąĄćĆęĘłŁńŃóÓśŚźŹżŻàÀáÁãÃâÂéÉêÊíÍóÓôÔõÕúÚüÜçÇÅåÄäÖöÆæØøÆæÐðØøÖöÞþÁáÉéÍíÓóÚúÝýàÀèÈìÌòÒùÙáÁéÉíÍóÓúÚüÜñÑâÂêÊîÎôÔûÛŵŴŷŶáÁéÉíÍóÓúÚýÝẃẂäÄëËïÏöÖüÜẅẄÿŸàÀèÈìÌòÒùÙẁẀỳỲāĀēĒīĪōŌūŪȳȲ ']+$/;
