<svg
  width="20px"
  height="15px"
  viewBox="0 0 20 15"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-labelledby="tick-icon-title tick-icon-desc"
  role="img"
>
  <!-- Generator: Sketch 63 (92445) - https://sketch.com -->
  <title id="tick-icon-title">Tick icon</title>
  <desc id="tick-icon-desc">This is a tick icon.</desc>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="008_Consent"
      transform="translate(-459.000000, -119.000000)"
      fill="#FFFFFF"
      class="fill"
      fill-rule="nonzero"
    >
      <g
        id="Stepper"
        transform="translate(449.000000, 92.000000)"
      >
        <g
          id="Group-5"
          transform="translate(0.000000, 14.000000)"
        >
          <path
            d="M16.7227535,27.2632479 L16.646958,27.2632479 L16.5711628,27.1931625 C16.4953673,27.1931625 16.4195721,27.1230771 16.3437766,27.0529917 L16.267981,26.982906 L11.2654853,21.7264959 C10.810713,21.2358974 10.810713,20.4649574 11.3412807,20.0444447 C11.5686669,19.8341881 11.8718484,19.694017 12.17503,19.694017 C12.4782116,19.694017 12.8571886,19.8341881 13.0845747,20.0444447 L17.177526,24.3897436 L27.0309268,14.0170941 C27.2583131,13.8068376 27.5614945,13.6666667 27.9404715,13.6666667 C28.2436529,13.6666667 28.5468347,13.8068376 28.774221,14.0170941 C29.2289931,14.4376068 29.3047887,15.2085469 28.8500162,15.6991453 L18.0112751,27.0529917 C17.93548,27.1230771 17.8596844,27.1931625 17.7838889,27.1931625 L17.7080937,27.1931625 L17.6322982,27.2632479 C17.4807075,27.3333333 17.3291167,27.3333333 17.177526,27.3333333 C17.025935,27.3333333 16.8743442,27.3333333 16.7227535,27.2632479 Z"
            id="Tick-White"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
