import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { LibAccordionItemService } from './lib-accordion-item.service';

@Component({
  selector: 'lib-accordion-item',
  templateUrl: './lib-accordion-item.component.html',
  styleUrls: ['./lib-accordion-item.component.scss'],
  animations: [
    trigger('accordionState', [
      state('false', style({ height: 0 })),
      state('true', style({ height: '*' })),
      transition('false => true', animate('.35s')),
      transition('true => false', animate('.35s')),
    ]),
    trigger('accordionIconRotate', [
      state('false', style({ transform: 'rotate(0)' })),
      state('true', style({ transform: 'rotate(180deg)' })),
      transition('false => true', animate('100ms')),
      transition('true => false', animate('100ms')),
    ]),
  ],
})
export class LibAccordionItemComponent implements OnInit {
  isOpen = false;
  @Input() idToScroll: number;
  @Input() uniqueSetId: string;
  @Input() bespokeScrollContainer = '';
  @Input() isLegacy = false;
  @Input() headerHeight? = 0;

  constructor(private accordionService: LibAccordionItemService) {}

  ngOnInit(): void {
    this.accordionService.openAccordion
      .pipe(
        filter((selectedAccordion: LibAccordionItemComponent) => selectedAccordion.uniqueSetId === this.uniqueSetId),
      )
      .subscribe(
        (selectedAccordion: LibAccordionItemComponent) => (this.isOpen = selectedAccordion === this && !this.isOpen),
      );
  }

  openAccordion(): void {
    this.accordionService.openAccordion.next(this);
  }

  scrollItemIntoView(): void {
    if (this.isOpen && this.isItemOutOfView()) {
      const element = document.querySelector('#accordion_' + this.idToScroll);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private isItemOutOfView(): boolean {
    const accordionItem = document.querySelector('#accordion_' + this.idToScroll)?.getBoundingClientRect();
    let isTopShown;
    let isBottomShown;
    if (this.bespokeScrollContainer) {
      const scrollContainer = document?.querySelector(this.bespokeScrollContainer)?.getBoundingClientRect();
      isTopShown = accordionItem.top >= scrollContainer.top;
      isBottomShown = accordionItem.bottom <= scrollContainer.bottom;
    } else {
      isTopShown = accordionItem.top >= this.headerHeight;
      isBottomShown = accordionItem.bottom <= window.innerHeight;
    }
    return !isTopShown || !isBottomShown;
  }
}
