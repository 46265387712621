<svg
  width="19px"
  height="11px"
  viewBox="0 0 19 11"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-labelledby="down-arrow-icon-title down-arrow-icon-desc"
  role="img"
>
  <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
  <title id="down-arrow-icon-title">Down arrow Icon</title>
  <desc id="down-arrow-icon-desc">This is a down arrow icon.</desc>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="49_Transactions"
      transform="translate(-978.000000, -466.000000)"
      fill="none"
      class="fill stroke"
      stroke="none"
    >
      <g
        id="Details"
        transform="translate(283.000000, 437.000000)"
      >
        <path
          d="M701.17564,34.5001135 L708.857204,26.3804662 C709.047599,26.1792138 709.047599,25.8521739 708.857204,25.6509344 C708.66681,25.449695 708.358366,25.4496821 708.167984,25.6509344 L700.142796,34.1366356 C700.051407,34.2332368 700,34.3640502 700,34.5009121 C700,34.637774 700.051407,34.7685874 700.142796,34.8661932 L708.167984,43.3490607 C708.358378,43.5503131 708.666823,43.5503131 708.857204,43.3490607 C709.047586,43.1478084 709.047599,42.8207684 708.857204,42.619529 L701.17564,34.5001135 Z"
          id="Page-1"
          transform="translate(704.500000, 34.500000) rotate(270.000000) translate(-704.500000, -34.500000) "
        ></path>
      </g>
    </g>
  </g>
</svg>
