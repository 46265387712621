<button
  [type]="buttonType"
  [ngClass]="['button ', !!styleName ? styleName : '']"
  [style.background-color]="bgColor"
  [style.color]="textColor"
  [style.border]="borderStyle"
  (click)="triggerClickEvent($event)"
  [disabled]="isButtonDisabled"
>
  <span
    class="inline-flex align-items-center"
    *ngIf="isIconButton; else simpleButtonText"
  >
    <ng-content></ng-content>
    <span>
      {{buttonText}}
    </span>
  </span>

  <ng-template #simpleButtonText>{{buttonText}}</ng-template>
</button>
