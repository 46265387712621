import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLUtils } from '@experian-digital/affordability-library';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../models/app-config.interface';

export const SECOND = 1000;
@Injectable()
export class ApplicationConfigService {
  static config: AppConfig = {
    apiUrl: '',
    authUrl: '',
    oktaAuthUrl: '',
    instrumentationKey: '',
    clientId: '',
    oktaClientId: '',
    digitalIdentityUrl: '',
    employmentVerificationUrl: '',
    transactionsStatusRetryTimeoutInSeconds: '30',
    workReportCoverageCheckerUrl: '',
    sanctionsStatusRetryIntervalInSeconds: '5',
    sanctionsStatusRetryTimeoutInSeconds: '180',
    pepsAndSanctionsStatusRetryAttempts: '24',
  };

  constructor(private http: HttpClient) {}

  load(): Promise<void> {
    if (!environment.production) {
      return;
    }

    const configFilePath = `assets/config/${environment.config}`;
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(configFilePath)
        .toPromise()
        .then((response: AppConfig) => {
          if (
            !response?.transactionsStatusRetryTimeoutInSeconds ||
            !response?.apiUrl ||
            !response?.authUrl ||
            !response?.clientId
          ) {
            reject(`Could not load file '${configFilePath}' or some of the configurations are not defined!`);
          }

          ApplicationConfigService.config.apiUrl = URLUtils.sanitizeUrl(response.apiUrl);
          ApplicationConfigService.config.digitalIdentityUrl = URLUtils.sanitizeUrl(response.digitalIdentityUrl);
          ApplicationConfigService.config.employmentVerificationUrl = URLUtils.sanitizeUrl(
            response.employmentVerificationUrl,
          );
          ApplicationConfigService.config.authUrl = URLUtils.sanitizeUrl(response.authUrl);
          ApplicationConfigService.config.instrumentationKey = URLUtils.sanitizeUrl(response.instrumentationKey);
          ApplicationConfigService.config.clientId = URLUtils.sanitizeUrl(response.clientId);
          ApplicationConfigService.config.transactionsStatusRetryTimeoutInSeconds = URLUtils.sanitizeUrl(
            response.transactionsStatusRetryTimeoutInSeconds,
          );
          ApplicationConfigService.config.workReportCoverageCheckerUrl = URLUtils.sanitizeUrl(
            response.workReportCoverageCheckerUrl,
          );
          ApplicationConfigService.config.pepsAndSanctionsStatusRetryAttempts = URLUtils.sanitizeUrl(
            response.pepsAndSanctionsStatusRetryAttempts,
          );
          ApplicationConfigService.config.oktaAuthUrl = URLUtils.sanitizeUrl(response.oktaAuthUrl);
          ApplicationConfigService.config.oktaClientId = URLUtils.sanitizeUrl(response.oktaClientId);
          resolve();
        })
        .catch((response: any) => {
          reject(`Could not load file '${configFilePath}': ${JSON.stringify(response)}`);
        });
    });
  }
}
