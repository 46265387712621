<svg
  width="28px"
  height="28px"
  viewBox="0 0 28 28"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-labelledby="search-icon-title search-icon-desc"
  role="img"
>
  <title id="search-icon-title">Search icon</title>
  <desc id="search-icon-desc">Magnifying glass icon depicting a search.</desc>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="2_Applications---A-Z"
      transform="translate(-778.000000, -299.000000)"
      fill="#BA2F7D"
      class="fill"
    >
      <g
        id="Search"
        transform="translate(478.000000, 285.000000)"
      >
        <g
          id="Search-icon"
          transform="translate(300.000000, 14.000000)"
        >
          <path
            d="M16.9137989,16.3749944 L16.6553073,16.6571844 L16.3731173,16.9163017 C14.8268603,18.3304581 12.8181341,19.1096872 10.7164916,19.1096872 C8.47500559,19.1096872 6.36820112,18.236838 4.78346369,16.6521006 C3.20122905,15.0698659 2.3303352,12.9624358 2.3303352,10.7183687 C2.3303352,8.47437989 3.20122905,6.36757542 4.78346369,4.78534078 C6.36820112,3.20060335 8.47500559,2.32775419 10.7164916,2.32775419 C12.957352,2.32775419 15.0647821,3.20060335 16.6495196,4.78534078 C18.234257,6.37007821 19.1071844,8.47758659 19.1071844,10.7183687 C19.1071844,12.8200894 18.3279553,14.8287374 16.9137989,16.3749944 Z M27.5138324,25.2703911 L19.3220335,17.0785922 C20.679095,15.2462346 21.4160112,13.0259441 21.4160112,10.7183687 C21.4160112,7.86056983 20.3032849,5.17335196 18.2823575,3.15250279 C16.2615084,1.13165363 13.5742905,0.0189273743 10.7164916,0.0189273743 C7.85869274,0.0189273743 5.17147486,1.13165363 3.1506257,3.15250279 C1.13227933,5.17077095 0.0215083799,7.85798883 0.0215083799,10.7183687 C0.0215083799,13.5794525 1.13227933,16.2666704 3.1506257,18.2843128 C5.17147486,20.305162 7.85869274,21.418514 10.7164916,21.418514 C13.024067,21.418514 15.2443575,20.6809721 17.0760112,19.3245363 L25.2678883,27.5164134 C25.5679888,27.8158883 25.9669497,27.9813855 26.3908603,27.9813855 C26.8147709,27.9813855 27.2137318,27.8158883 27.5138324,27.5164134 C28.133352,26.8968939 28.133352,25.8892849 27.5138324,25.2703911 L27.5138324,25.2703911 Z"
            id="Fill-14"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
