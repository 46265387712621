import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LibButtonModule } from '@experian-digital/affordability-library';
import { SvgModule } from '../../assets/svgs/svg.module';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { TimeoutService } from './services/timeout.service';

@NgModule({
  declarations: [ErrorMessageComponent],
  imports: [CommonModule, RouterModule, SvgModule, LibButtonModule],
  exports: [CommonModule, RouterModule, SvgModule, LibButtonModule, ErrorMessageComponent],
  providers: [TimeoutService],
})
export class AppSharedModule {}
