<svg
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 512 512"
  style="enable-background:new 0 0 512 512;"
  xml:space="preserve"
  aria-labelledby="plus-icon-title plus-icon-desc"
  role="img"
>
  <title id="plus-icon-title">Plus icon</title>
  <desc id="plus-icon-desc">This is a plus icon depicting addition.</desc>
  <g>
    <g>
      <polygon
        points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 		"
        class="fill"
        fill="#26478D"
      />
    </g>
  </g>
</svg>
