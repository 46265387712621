import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LibModalService {
  static isOverlayVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /**
   *  isModalOpenState$: keeps state of modal allowing it to finish before setting modal to null on animation callback
   */
  isModalOpenState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  modalButtonMessage$: BehaviorSubject<string> = new BehaviorSubject('');
}
