import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lib-text-with-button',
  templateUrl: './lib-text-with-button.component.html',
  styleUrls: ['./lib-text-with-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LibTextWithButtonComponent {
  @Input() inputLabel: string;
  @Input() errorMessage: string;
  @Input() buttonLabel: string;
  @Input() control: UntypedFormControl;

  @Output() clickEvent = new EventEmitter();

  buttonClicked(event: string): void {
    if (this.control.valid) {
      this.clickEvent.emit(event);
    }
  }

  get fieldTouchedAndInvalid(): boolean {
    return this.errorMessage && this.control.touched && !this.control.valid;
  }
}
