import { Account, AccountType, BWAResponseType } from '@experian-digital/affordability-library';

export class AccountHelper {
  public static getBWAMessage(account: Account): string {
    const { bwaStatus, bwaScore } = account;

    switch (bwaStatus) {
      case BWAResponseType.Passed: {
        return bwaScore + ' - Passed';
      }
      default: {
        return '-';
      }
    }
  }

  public static getBWAInfoMessage(account: Account): string {
    const { bwaStatus } = account;

    switch (bwaStatus) {
      case BWAResponseType.Passed: {
        return 'Experian carries out a check to verify if the account belongs to the customer. Pass with a score means we were able to verify this.';
      }
      default: {
        return `' - ' means we were unable to verify the account as we did not have enough information on the account to perform a check. We would suggest you take additional steps if required to verify the ownership of the account.`;
      }
    }
  }

  public static getAccountType(account: Account): string {
    return account.type === AccountType.Personal ? 'Personal' : 'Joint';
  }
}
