import { Amount } from './amount.interface';
export interface Account {
  integrationId?: string;
  ownedItemId: number;
  accountNumber: string;
  itemId: string;
  name: string;
  bank: string;
  bankImageUrl: string;
  balance: Amount;
  defaultBalance?: Amount;
  sortCode: string;
  type: number;
  isActive?: boolean;
  updatedDate?: Date;
  bwaScore?: number;
  bwaStatus?: BWAResponseType;
  overdraft?: Amount;
}

export enum AccountType {
  Personal = 1,
  Joint = 2,
}

export enum BWAResponseType {
  Passed = 1,
  Failed = 2,
  Unverified = 3,
  TechnicalError = 4,
}
