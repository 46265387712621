<svg
  width="21px"
  height="20px"
  viewBox="0 0 21 20"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  aria-labelledby="close-icon-title close-icon-desc"
  role="img"
>
  <title id="close-icon-title">Close icon</title>
  <desc id="close-icon-desc">This is a close icon.</desc>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      transform="translate(-812.000000, -569.000000)"
      fill="#BA2F7D"
      class="fill"
      fill-rule="nonzero"
    >
      <g
        id="Popup"
        transform="translate(446.000000, 549.000000)"
      >
        <g
          id="Close"
          transform="translate(366.000000, 20.000000)"
        >
          <path
            d="M1.24852351,20.1493288 L1.167737,20.1493288 L1.08695085,20.0738169 C1.00616434,20.0738169 0.925378191,19.998305 0.844591684,19.9227931 C1.34685877,20.7167997 1.31992994,20.6916289 0.763805177,19.8472808 C0.207680419,19.0029327 0.530825971,18.071618 1.73324183,17.0533367 L18.2354689,-0.122439866 C18.477828,-0.348976005 18.8009733,-0.5 19.2049052,-0.5 C19.5280505,-0.5 19.8511961,-0.348976005 20.0935553,-0.122439866 C20.5782733,0.330632046 20.6590598,1.16126395 20.1743415,1.68984815 L2.62189163,19.9227931 C2.54110548,19.998305 2.46031897,20.0738169 2.37953247,20.0738169 L2.29874631,20.0738169 L2.21795981,20.1493288 C2.05638715,20.2248408 1.89481449,20.2248408 1.73324183,20.2248408 C1.57166882,20.2248408 1.41009616,20.2248408 1.24852351,20.1493288 Z"
            id="path4152"
            transform="translate(10.500000, 10.000000) rotate(90.000000) translate(-10.500000, -10.000000) "
          ></path>
          <path
            d="M1.24852351,20.1493288 L1.167737,20.1493288 L1.08695085,20.0738169 C1.00616434,20.0738169 0.925378191,19.998305 0.844591684,19.9227931 C1.34685877,20.7167997 1.31992994,20.6916289 0.763805177,19.8472808 C0.207680419,19.0029327 0.530825971,18.071618 1.73324183,17.0533367 L18.2354689,-0.122439866 C18.477828,-0.348976005 18.8009733,-0.5 19.2049052,-0.5 C19.5280505,-0.5 19.8511961,-0.348976005 20.0935553,-0.122439866 C20.5782733,0.330632046 20.6590598,1.16126395 20.1743415,1.68984815 L2.62189163,19.9227931 C2.54110548,19.998305 2.46031897,20.0738169 2.37953247,20.0738169 L2.29874631,20.0738169 L2.21795981,20.1493288 C2.05638715,20.2248408 1.89481449,20.2248408 1.73324183,20.2248408 C1.57166882,20.2248408 1.41009616,20.2248408 1.24852351,20.1493288 Z"
            id="path4152"
            transform="translate(10.500000, 10.000000) scale(-1, 1) rotate(90.000000) translate(-10.500000, -10.000000) "
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
