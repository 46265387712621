import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownMenuItem } from './lib-dropdown-menu-items.model';

@Component({
  selector: 'lib-dropdown-menu',
  templateUrl: './lib-dropdown-menu.component.html',
  styleUrls: ['./lib-dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibDropdownMenuComponent {
  @Input() title = 'More';
  @Input() dropdownPosition? = 'left';
  @Input() list: DropdownMenuItem[];
  @Input() className: string;
  @Input() isDisabled: boolean;
  @Input() styleOptions? = {
    menuHeadingStyles: { 'font-size': '1.8rem', 'letter-spacing': '0rem' } as Record<string, string>,
    menuItemStyles: { 'font-size': '1.8rem', 'letter-spacing': '0rem' } as Record<string, string>,
    caretStyles: { color: 'dark-blue' } as Record<string, string>,
  };
  @Input() isSelectedTickVisible = false;
  @Input() isHighlighted = false;
  @Output() selected: EventEmitter<DropdownMenuItem> = new EventEmitter();
  isDropDownActive = false;
  focusIndex = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onSelect(item: DropdownMenuItem, event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.list.forEach((item) => (item.isSelected = false));
    item.isSelected = true;
    if (!item?.disabled) {
      this.isDropDownActive = false;
      if (item.path) {
        this.router.navigate([item.path], { relativeTo: this.route });
        return;
      }
      this.selected.emit(item);
    }
  }

  getItemStyles(item: DropdownMenuItem) {
    return {
      'font-size': '1.5rem',
      'font-weight': 'bold',
      color: 'black',
    };
  }

  getCombinedStyles(item: DropdownMenuItem) {
    return item.isSelected && this.isHighlighted
      ? { ...this.getItemStyles(item) }
      : { ...this.styleOptions.menuItemStyles };
  }

  toggleActiveWithKey(event: KeyboardEvent): void {
    if (!this.isDropDownActive) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropDownActive = true;
    }
  }

  onFocus(index: number): void {
    this.focusIndex = index;
  }

  onBlur(index: number): void {
    if (index === this.list.length - 1) {
      this.isDropDownActive = false;
    }
  }
}
