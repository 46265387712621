<div
  class="main"
  [class.main--no-nav-bar]="hideNavBar"
  #top
>
  <div class="main__content px-sm pb-sm">
    <router-outlet (activate)="scrollToTop()"></router-outlet>
    <div
      [attr.data-qa-id]="'lib-spinner'"
      *ngIf="loadingInitial$ | async as loading"
      class="pt-md"
    >
      <lib-spinner [imageUrl]="loading?.imageUrl">
        <h4>{{ loading?.infoText }}</h4>
      </lib-spinner>
    </div>
    <aff-error-message
      *ngIf="(error$ | async) && !(loadingInitial$ | async)"
      [error]="error$ | async"
      (retry)="navigateTo()"
      [attr.data-qa-id]="'error-message'"
    ></aff-error-message>
  </div>
</div>

<div class="button-cont flex">
  <!-- Scroll to top floating button -->
  <lib-chevron-icon
    *ngIf="isScrollToTopIconVisible"
    [@inOutAnimation]
    class="icon-theme icon-xxs p-sm c-pointer mr-xxs"
    (click)="scrollToTop(top)"
  ></lib-chevron-icon>

  <lib-faq-icon
    style="padding: 9px"
    class="icon-theme icon-md c-pointer"
    *ngIf="showFaq"
    (click)="isFaqModalVisible = true"
    [attr.data-qa-id]="'faq-button'"
  ></lib-faq-icon>
</div>

<lib-modal
  [attr.data-qa-id]="'faq-modal'"
  (closeModalEvent)="isFaqModalVisible = false"
  [templateRef]="content"
  [alignToTop]="true"
  *ngIf="isFaqModalVisible"
>
  <ng-template #content>
    <section class="faq-header py-xs color-off-white">
      <h3 class="text-align-center">Frequently asked questions</h3>
      <div class="p-xs design-refresh">
        <lib-accordion-item
          *ngFor="let item of faqList; let i = index"
          [uniqueSetId]="'user-auth-faqs'"
          [idToScroll]="'_faq' + i"
          [bespokeScrollContainer]="'.modal-container'"
          [isLegacy]="false"
        >
          <p
            class="color-grey"
            title
          >{{ item.title }}</p>
          <div
            body
            class="small font-weight-regular color-black"
            [innerHTML]="item.body"
          ></div>
        </lib-accordion-item>
      </div>
    </section>
  </ng-template>
</lib-modal>

<!-- Notifications -->
<p-toast
  position="top-right"
  styleClass="custom-toast"
>
  <ng-template
    let-message
    pTemplate="message"
  >
    <div
      class="notification-container flex py-xxs"
      [attr.data-qa-id]="'notification'"
    >

      <lib-tick-icon
        [attr.data-qa-id]="'green-icon'"
        *ngIf="message.severity === 'success'; else errorIcon"
        class="green-icon icon-xs px-sm py-xxxs"
      ></lib-tick-icon>

      <div class="text-align-left">
        <h4 class="toast-title">{{ message.summary }}</h4>
        <p>
          {{ message.detail }}
          <a
            *ngIf="message.severity === 'success'; else retryRefreshMessage"
            [routerLink]="[message.data]"
          >Go to accounts list</a>
        </p>
      </div>
    </div>

    <ng-template #retryRefreshMessage>
      Please <a [routerLink]="[message.data]">go to accounts list</a> to try again.
    </ng-template>

    <ng-template #errorIcon>
      <lib-close-icon
        [attr.data-qa-id]="'red-icon'"
        class="icon-red icon-xxs px-sm py-xxs"
      ></lib-close-icon>
    </ng-template>
  </ng-template>
</p-toast>
