import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LibCloseIconModule } from '../icons/lib-close-icon/lib-close-icon.module';
import { LibWorkReportSearchIconModule } from '../icons/lib-workreport-search-icon/lib-workreport-search-icon.module';
import { LibDownArrowIconModule } from './../icons/lib-down-arrow-icon/lib-down-arrow-icon.module';
import { LibSearchIconModule } from './../icons/lib-search-icon/lib-search-icon.module';
import { LibTypeaheadSearchComponent } from './lib-typeahead-search.component';

@NgModule({
  declarations: [LibTypeaheadSearchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LibDownArrowIconModule,
    LibSearchIconModule,
    LibWorkReportSearchIconModule,
    LibCloseIconModule,
  ],
  exports: [LibTypeaheadSearchComponent],
})
export class LibTypeaheadSearchModule {}
