import { Injector, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export class URLUtils {
  static injector: Injector = null;

  static withoutQueryParams(route: string): string {
    return route.split('?')[0];
  }

  static getPath(route: string): string {
    return route.split('/')[1];
  }

  static sanitizeUrl(url: string): string {
    const sanitizer: DomSanitizer = URLUtils.injector.get(DomSanitizer);

    return sanitizer.sanitize(SecurityContext.URL, url);
  }
}
