import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownItem } from './../../models/input.interface';

export enum KEY_CODE {
  UP_ARROW = 'ArrowUp',
  DOWN_ARROW = 'ArrowDown',
  ENTER = 'Enter',
  ESC = 'Esc',
  ESCAPE = 'Escape',
  SPACE = 'Space',
  TAB = 'Tab',
}

@Component({
  selector: 'lib-dropdown',
  templateUrl: './lib-dropdown.component.html',
  styleUrls: ['lib-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibDropdownComponent implements OnChanges {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() list: DropdownItem[] = [];
  @Input() errorMessage: string;
  @Input() control: UntypedFormControl;
  @Input() classList: string = 'dropdown-md';
  @Input() spellcheck? = false;
  @Input() showValidationError? = true;
  @Input() disabled? = false;
  @Input() customZindex?: number;

  @Output() onChange: EventEmitter<DropdownItem> = new EventEmitter();

  @ViewChild('dropDownList')
  dropDownList: ElementRef;

  selectedItem: DropdownItem;
  isDropdownOpen: boolean = false;
  hasFocus = false;
  showAbove = false;

  constructor(
    private eRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {}

  // Close when the user clicks outside the component
  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (this.isDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.control?.markAllAsTouched();
    }
  }

  // Keydown listener when the dropdown is open
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (!this.hasFocus) {
      return;
    }
    // Down arrow
    if (event.key == KEY_CODE.DOWN_ARROW && this.isDropdownOpen) {
      const index: number = this.list.indexOf(this.selectedItem);
      this.selectedItem = this.list[index == this.list.length - 1 ? index : index + 1];

      this.onKeyDownSelect(event);
    }

    // Up arrow
    if (event.key == KEY_CODE.UP_ARROW && this.isDropdownOpen) {
      const index: number = this.list.indexOf(this.selectedItem);
      this.selectedItem = this.list[index > 0 ? index - 1 : 0];

      this.onKeyDownSelect(event);
    }
    // Enter or Esc (Esc used by IE/Edge)
    if (
      (event.key == KEY_CODE.ENTER || event.key == KEY_CODE.ESC || event.key == KEY_CODE.ESCAPE) &&
      this.isDropdownOpen
    ) {
      this.control?.markAllAsTouched();
      event.preventDefault();
    }

    // Space
    if (event.key == KEY_CODE.SPACE || event.key == KEY_CODE.ENTER) {
      this.toggleDropdown();
      event.preventDefault();
    }

    // Tab
    if (event.key == KEY_CODE.TAB && this.isDropdownOpen) {
      this.toggleDropdown();
      this.control?.markAllAsTouched();
      event.preventDefault();
    }
  }

  ngOnChanges(e) {
    this.initDropdown();
  }

  initDropdown(): void {
    this.selectedItem = this.list.find((item) => item.value === this.control.value);
  }

  onSelect(selectedItem: DropdownItem): void {
    this.selectedItem = selectedItem;
    this.control?.setValue(selectedItem.value);
    this.toggleDropdown();
    this.control?.markAllAsTouched();
    this.onChange.emit(selectedItem);
  }

  onKeyDownSelect(event: KeyboardEvent): void {
    this.control?.setValue(this.selectedItem.value);
    this.control?.markAllAsTouched();
    this.onChange.emit(this.selectedItem);
    event.preventDefault();
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;

    if (this.isDropdownOpen) {
      this.cd.detectChanges();
      const dropDownDimensions = this.dropDownList?.nativeElement.getBoundingClientRect();
      this.showAbove = dropDownDimensions.bottom > window.innerHeight;
      return;
    }
    this.showAbove = false;
  }

  focus(): void {
    this.hasFocus = true;
  }

  blur(): void {
    this.hasFocus = false;
  }

  get isInvalid(): boolean {
    return this.showValidationError && this.control?.touched && this.control?.invalid;
  }
}
