import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibPlusIconComponent } from './lib-plus-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LibPlusIconComponent],
  exports: [LibPlusIconComponent],
})
export class LibPlusIconModule {}
