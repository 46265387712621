import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { StoreState } from '../../models/store.model';
import { STORAGE_KEYS } from '../enums/storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class StoreService extends ObservableStore<StoreState> {
  constructor() {
    super({
      trackStateHistory: true,
    });
  }

  get<T>(field: string): T {
    return this.getState() ? this.getState()[field] : null;
  }

  add<T>(field: string, data: T): StoreState {
    return this.setState({ [field]: data }, 'add_' + field);
  }

  addItemToList<T>(field: string, data: T): StoreState {
    const state: StoreState = this.getState();
    state[field].push(field);

    return this.setState(state, 'add_item_to_list_' + field);
  }

  removeItemFromList<T>(field: string, data: T): StoreState {
    const state: StoreState = this.getState();
    state[field] = state[field].filter((item) => item !== data);

    return this.setState(state, 'remove_item_from_list_' + field);
  }

  remove<T>(field: string): StoreState {
    return this.setState({ [field]: null }, 'remove_' + field);
  }

  clearAll(): void {
    Object.keys(STORAGE_KEYS).forEach((item) => {
      this.setState({ [item]: null }, 'remove_' + item);
    });
  }
}
