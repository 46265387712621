import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApplicationAuthGuard,
  AuthService,
  ErrorHandlerService,
  LibAccordionItemModule,
  LibCloseIconModule,
  LibConsentIconModule,
  LibDropdownMenuModule,
  LibModalModule,
  LibSpinnerModule,
  LibTickIconModule,
  URLUtils,
} from '@experian-digital/affordability-library';
import { PortalRoles } from '@experian-digital/affordability-library/src/lib/shared/enums/portal-roles.enum';
import { AppSharedModule } from '@portal-shared/app-shared.module';
import { ApplicationConfigService } from '@portal-shared/services/config.service';
import { SanitizerHttpInterceptor } from '@portal-shared/services/http.interceptor';
import { WindowRef } from '@portal-shared/services/window.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from '../../src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    LibConsentIconModule,
    LibTickIconModule,
    LibCloseIconModule,
    LibSpinnerModule,
    AppSharedModule,
    LibModalModule,
    LibAccordionItemModule,
    LibDropdownMenuModule,
    OAuthModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    ApplicationConfigService,
    WindowRef,
    AuthService,
    ApplicationAuthGuard,
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ApplicationConfigService) => (): Promise<void> => config.load(),
      deps: [ApplicationConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SanitizerHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: 'environment', useValue: environment },
    { provide: 'portalType', useValue: PortalRoles.Advisor },
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    URLUtils.injector = this.injector;
  }
}
