import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibPaginationComponent } from './lib-pagination.component';

@NgModule({
  declarations: [LibPaginationComponent],
  imports: [CommonModule],
  exports: [LibPaginationComponent],
})
export class LibPaginationModule {}
