import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { ApplicationConfigService } from '../services/config.service';

export function getClientSettings(isOktaLogin: boolean = false): AuthConfig {
  const portalUrl = window.location.protocol + '//' + window.location.host;
  return {
    issuer: environment.production ? getIssuer(isOktaLogin) : 'http://localhost:44353',
    redirectUri: environment.production ? portalUrl : 'http://localhost:4200',
    clientId: getClientId(isOktaLogin),
    responseType: 'code',
    scope: getScope(isOktaLogin),
    showDebugInformation: environment.production,
    postLogoutRedirectUri: portalUrl,
    sessionChecksEnabled: true,
  };
}

function getIssuer(isOktaLogin): string {
  return isOktaLogin ? ApplicationConfigService.config.oktaAuthUrl : ApplicationConfigService.config.authUrl;
}

function getClientId(isOktaLogin): string {
  return isOktaLogin ? ApplicationConfigService.config.oktaClientId : ApplicationConfigService.config.clientId;
}

function getScope(isOktaLogin): string {
  return isOktaLogin ? 'openid profile' : 'openid profile portal departmentShortName role';
}
