import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibDropdownModule } from '../lib-dropdown/lib-dropdown.module';
import { LibTextInputModule } from '../lib-input/lib-input.module';
import { LibScrollableListModule } from '../lib-scrollable-list/lib-scrollable-list.module';
import { LibSpinnerModule } from '../lib-spinner/lib-spinner.module';
import { LibTextWithButtonModule } from '../lib-text-with-button/lib-text-with-button.module';
import { LibSpinnerV2Module } from '../v2/lib-spinner-v2/lib-spinner-v2.module';
import { LibAddressPickerComponent } from './lib-address-picker.component';

@NgModule({
  declarations: [LibAddressPickerComponent],
  imports: [CommonModule, LibTextWithButtonModule, LibScrollableListModule, LibTextInputModule, LibSpinnerModule, LibDropdownModule, LibSpinnerV2Module],
  exports: [LibAddressPickerComponent],
})
export class LibAddressPickerModule { }
