import { NgModule } from '@angular/core';
import { LibAccountDetailsIconModule } from './lib-account-details-icon/lib-account-details-icon.module';
import { LibAffordabilityPassportIconModule } from './lib-affordability-passport-icon/lib-affordability-passport-icon.module';
import { LibAlertIconModule } from './lib-alert-icon/lib-alert-icon.module';
import { LibBackIconModule } from './lib-back-icon/lib-back-icon.module';
import { LibBankIconModule } from './lib-bank-icon/lib-bank-icon.module';
import { LibBankSecurityIconModule } from './lib-bank-security-icon/lib-bank-security-icon.module';
import { LibCalendarIconModule } from './lib-calendar-icon/lib-calendar-icon.module';
import { LibCarFinanceIconModule } from './lib-car-finance-icon/lib-car-finance-icon.module';
import { LibChevronIconModule } from './lib-chevron-icon/lib-chevron-icon.module';
import { LibChevronMoreIconModule } from './lib-chevron-more-icon/lib-chevron-more-icon.module';
import { LibCircleIconModule } from './lib-circle-icon/lib-circle-icon.module';
import { LibCircleTickIconModule } from './lib-circle-tick-icon/lib-circle-tick-icon.module';
import { LibCloseIconModule } from './lib-close-icon/lib-close-icon.module';
import { LibConnectAccountsIconModule } from './lib-connect-accounts-icon/lib-connect-accounts-icon.module';
import { LibConsentDeclinedIconModule } from './lib-consent-declined-icon/lib-consent-declined-icon.module';
import { LibConsentIconModule } from './lib-consent-icon/lib-consent-icon.module';
import { LibContactBubbleIconModule } from './lib-contact-bubble-icon/lib-contact-bubble-icon.module';
import { LibContactIconModule } from './lib-contact-icon/lib-contact-icon.module';
import { LibContinueIconModule } from './lib-continue-icon/lib-continue-icon.module';
import { LibCreditCardsIconModule } from './lib-credit-cards-icon/lib-credit-cards-icon.module';
import { LibDownArrowIconModule } from './lib-down-arrow-icon/lib-down-arrow-icon.module';
import { LibDownloadSmallIconModule } from './lib-download-small-icon/lib-download-small-icon.module';
import { LibDownloadIconModule } from './lib-download.icon/lib-download-icon.module';
import { LibEditIconModule } from './lib-edit-icon/lib-edit-icon.module';
import { LibEmailVerificationIconModule } from './lib-email-verification-icon/lib-email-verification-icon.module';
import { LibEmploymentIconModule } from './lib-employment-icon/lib-employment-icon.module';
import { LibEuroTransactionsIconModule } from './lib-euro-transactions-icon/lib-euro-transactions-icon.module';
import { LibExpenditureIconModule } from './lib-expenditure-icon/lib-expenditure-icon.module';
import { LibExperianLogoSvgModule } from './lib-experian-logo-svg/lib-experian-logo-svg.module';
import { LibEyeIconModule } from './lib-eye-icon/lib-eye-icon.module';
import { LibFactFindIconModule } from './lib-fact-find-icon/lib-fact-find-icon.module';
import { LibFaqIconModule } from './lib-faq-icon/lib-faq-icon.module';
import { LibFetchingTransactionsEuroIconModule } from './lib-fetching-transactions-euro-icon/lib-fetching-transactions-euro-icon.module';
import { LibFetchingTransactionsIconModule } from './lib-fetching-transactions-icon/lib-fetching-transactions-icon.module';
import { LibFillCrossRoundIconModule } from './lib-fill-cross-round-icon/lib-fill-cross-round-icon.module';
import { LibFillTickRoundIconModule } from './lib-fill-tick-round-icon/lib-fill-tick-round-icon.module';
import { LibFloppyIconModule } from './lib-floppy-icon/lib-floppy-icon.module';
import { LibIdentityIconModule } from './lib-identity-icon/lib-identity-icon.module';
import { LibIncomeIconModule } from './lib-income-icon/lib-income-icon.module';
import { LibInfoIconModule } from './lib-info-icon/lib-info-icon.module';
import { LibInfoOutlineIconModule } from './lib-info-outline-icon/lib-info-outline-icon.module';
import { LibLoanIconModule } from './lib-loan-icon/lib-loan-icon.module';
import { LibLockIconModule } from './lib-lock-icon/lib-lock-icon.module';
import { LibMortgageIconModule } from './lib-mortgage-icon/lib-mortgage-icon.module';
import { LibOpenLinkIconModule } from './lib-open-link-icon/lib-open-link-icon.module';
import { LibPlusIconModule } from './lib-plus-icon/lib-plus-icon.module';
import { LibRecategorisationIconModule } from './lib-recategorisation-icon/lib-recategorisation-icon.module';
import { LibSaveIconModule } from './lib-save-icon/lib-save-icon.module';
import { LibSearchIconModule } from './lib-search-icon/lib-search-icon.module';
import { LibStarIconModule } from './lib-star-icon/lib-star-icon.module';
import { LibTickIconModule } from './lib-tick-icon/lib-tick-icon.module';
import { LibTransactionsIconModule } from './lib-transactions-icon/lib-transactions-icon.module';
import { LibUploadIconModule } from './lib-upload-icon/lib-upload-icon.module';
import { LibWarningFilledIconModule } from './lib-warning-filled-icon/lib-warning-filled-icon.module';
import { LibWarningIconModule } from './lib-warning-icon/lib-warning-icon.module';
import { LibWarningOutlineIconModule } from './lib-warning-outline-icon/lib-warning-outline-icon.module';
import { LibWorkReportSearchIconModule } from './lib-workreport-search-icon/lib-workreport-search-icon.module';

const MODULES = [
  LibAffordabilityPassportIconModule,
  LibAccountDetailsIconModule,
  LibCarFinanceIconModule,
  LibConsentIconModule,
  LibConsentDeclinedIconModule,
  LibEmailVerificationIconModule,
  LibFetchingTransactionsIconModule,
  LibIncomeIconModule,
  LibLoanIconModule,
  LibMortgageIconModule,
  LibTransactionsIconModule,
  LibBankIconModule,
  LibConnectAccountsIconModule,
  LibCreditCardsIconModule,
  LibExpenditureIconModule,
  LibIdentityIconModule,
  LibInfoIconModule,
  LibTickIconModule,
  LibUploadIconModule,
  LibChevronIconModule,
  LibChevronMoreIconModule,
  LibCircleTickIconModule,
  LibSearchIconModule,
  LibWorkReportSearchIconModule,
  LibBackIconModule,
  LibLockIconModule,
  LibCloseIconModule,
  LibFetchingTransactionsEuroIconModule,
  LibBankSecurityIconModule,
  LibFillTickRoundIconModule,
  LibFillCrossRoundIconModule,
  LibEuroTransactionsIconModule,
  LibPlusIconModule,
  LibEditIconModule,
  LibSaveIconModule,
  LibCalendarIconModule,
  LibFactFindIconModule,
  LibDownloadIconModule,
  LibContactBubbleIconModule,
  LibContactIconModule,
  LibFaqIconModule,
  LibDownArrowIconModule,
  LibEyeIconModule,
  LibWarningIconModule,
  LibEmploymentIconModule,
  LibAlertIconModule,
  LibRecategorisationIconModule,
  LibDownloadSmallIconModule,
  LibWarningOutlineIconModule,
  LibWarningFilledIconModule,
  LibOpenLinkIconModule,
  LibContinueIconModule,
  LibExperianLogoSvgModule,
  LibFloppyIconModule,
  LibCircleIconModule,
  LibInfoOutlineIconModule,
  LibStarIconModule,
];

@NgModule({
  imports: MODULES,
  exports: MODULES,
})
export class LibIconsModule {}
