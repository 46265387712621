import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Address, AddressListItem } from '../../models/address.interface';
import { DropdownItem } from '../../models/input.interface';
import { ArrayUtils } from '../../shared/utils/array.utils';
import { MONTHS } from '../lib-multiple-addresses/lib-multiple-address.helper';

@Component({
  selector: 'lib-address-picker',
  templateUrl: './lib-address-picker.component.html',
  styleUrls: ['./lib-address-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LibAddressPickerComponent implements OnInit, OnChanges {
  @Input() addressForm: UntypedFormGroup;
  @Input() addressList: AddressListItem[];
  @Input() selectedAddress: Address;
  @Input() loadingMessage: string;
  @Input() showCountry: boolean;
  @Input() isNewAddressAdded: boolean;
  @Input() showOldSpinner: boolean = true;

  @Output() getAddresses = new EventEmitter();
  @Output() getSelectedAddress = new EventEmitter();
  @Output() resetFormAddress = new EventEmitter();

  showManualAddress: boolean;
  displayedAddressList: string[];
  noAddressesError: boolean;
  dateInfo = { months: MONTHS, years: ArrayUtils.generateArrayOfYears(100) };
  country: DropdownItem[] = [
    { value: 'UK', title: 'United Kingdom' },
  ];

  ngOnInit(): void {
    this.addressForm.setValidators(this.houseNameNumberValidator);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.noAddressesError = false;
    if (changes.addressList && this.addressList) {
      if (!this.addressList.length && !changes.addressList.firstChange) {
        this.noAddressesError = true;
        return;
      }
      this.displayedAddressList = this.addressList.map((address) => address.streetName);
    }
    if (changes.selectedAddress && this.selectedAddress) {
      if (this.isAddressEmpty() && !changes.selectedAddress.firstChange) {
        this.noAddressesError = true;
        return;
      }
      if (!this.isNewAddressAdded) {
        this.updateSelectedAddress();
      }
    }
    this.isNewAddressAdded = false;
  }

  getAddressList(postcode: string): void {
    this.getAddresses.emit(postcode);
  }

  clickedListItem(itemIndex: number): void {
    this.getSelectedAddress.emit(itemIndex);
  }

  isHouseNameOrNumberInvalid(): boolean {
    return (
      this.addressForm.errors &&
      this.addressForm.errors.awaitingNameOrNumber &&
      (this.addressForm.controls.houseName.touched || this.addressForm.controls.houseNumber.touched)
    );
  }

  toggleManualAddress(event): void {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    this.showManualAddress = !this.showManualAddress;
    if (!this.showManualAddress) {
      this.resetAddress();
    }
  }

  private resetAddress(): void {
    this.addressList = [];
    this.resetFormAddress.emit();
  }

  private isAddressEmpty(): boolean {
    return Object.values(this.selectedAddress).every((value: string) => !value);
  }

  private houseNameNumberValidator(form: UntypedFormGroup): { [key: string]: boolean } | null {
    if (form) {
      const isEitherCompleted = !!form.value.houseName || !!form.value.houseNumber;
      if (!isEitherCompleted) {
        return { awaitingNameOrNumber: true };
      }
    }
    return null;
  }

  private updateSelectedAddress(): void {
    this.addressForm.patchValue(this.selectedAddress);
    this.addressForm.markAllAsTouched();
    this.addressForm.controls?.country?.markAsUntouched();
    this.addressForm.controls?.month?.markAsUntouched();
    this.addressForm.controls?.year?.markAsUntouched();
    this.showManualAddress = true;
  }

  onDropdownChange(value: string) {
    // NOTE: For retrospective validation of all previous dates against current date
    this.addressForm.get(value).updateValueAndValidity();
  }
}
