import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ObservableStore } from '@codewithdan/observable-store';
import { ReduxDevToolsExtension } from '@codewithdan/observable-store-extensions';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');

if (msie < 0 && !navigator.userAgent.match(/Trident.*rv\:11\./)) {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

  // Observable state management redux extension tool config
  ObservableStore.globalSettings = {
    trackStateHistory: true,
  };
  ObservableStore.addExtension(new ReduxDevToolsExtension());
}
