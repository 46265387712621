import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LibChevronIconModule, LibFaqIconModule } from '@experian-digital/affordability-library';
import { HomeIconComponent } from './home-icon/home-icon.component';
import { LinkedIconComponent } from './linked-icon/linked-icon.component';
import { LogoutIconComponent } from './logout-icon/logout-icon.component';

@NgModule({
  declarations: [HomeIconComponent, LinkedIconComponent, LogoutIconComponent],
  imports: [CommonModule, LibChevronIconModule, LibFaqIconModule],
  exports: [HomeIconComponent, LinkedIconComponent, LogoutIconComponent, LibChevronIconModule, LibFaqIconModule],
})
export class SvgModule {}
