<div class="modal-container">
  <div
    class="modal-box"
    [class.modal-box-align-to-top]="alignToTop"
  >
    <div class="modal-content">
      <div class="modal-header">
        <button
          *ngIf="closeIconVisible"
          id="close-modal-button"
          (click)="triggerCloseModal()"
        >
          <lib-close-icon class="icon-theme icon-xs"></lib-close-icon>
        </button>
      </div>

      <div class="modal-body">
        <ng-container *ngTemplateOutlet="templateRef"></ng-container>
      </div>
    </div>
  </div>
</div>

<div class="bg-overlay"></div>
