import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { KEY_CODE } from '../lib-dropdown/lib-dropdown.component';
import { DropdownItem } from './../../models/input.interface';

@Component({
  selector: 'lib-typeahead-search',
  templateUrl: './lib-typeahead-search.component.html',
  styleUrls: ['lib-typeahead-search.component.scss'],
})
export class LibTypeaheadSearchComponent {
  @Input() title: string = '';
  @Input() placeholder: string;
  @Input() list: DropdownItem[] = [];
  @Input() errorMessage: string;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() spellcheck? = false;
  @Input() validationMessage?: string = 'Please provide a valid input.';

  @Output() onSearchChange: EventEmitter<any> = new EventEmitter();
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
  @Output() cannotFindEmployer: EventEmitter<void> = new EventEmitter();

  inputText: string = '';
  selectedItem: DropdownItem;
  filteredList: DropdownItem[] = [];
  isDropdownOpen: boolean = false;
  isNoResult: boolean = false;
  subscribe$: Subject<void> = new Subject();

  constructor(private eRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.errorMessage) {
      this.list.length = 0;
    }
    if (!changes?.list?.firstChange && this.inputText?.trim().length > 0) {
      setTimeout(() => {
        if (this.list.length > 0) {
          if (this.isNoResult) this.isNoResult = false;
          this.isDropdownOpen = true;
        }
      }, 300);

      setTimeout(() => {
        if (this.list.length == 0) {
          this.isDropdownOpen = false;
          this.isNoResult = true;
        }
      }, 600);
    }
  }
  @HostListener('document:click', ['$event'])
  clickAway(event): void {
    if (this.isDropdownOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.toggleDropdown();
      this.control?.markAllAsTouched();
      this.isNoResult = false;
    }
    if (this.isNoResult) this.isNoResult = false;
  }

  // Keydown listener when the dropdown is open
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (!this.isDropdownOpen) {
      this.isNoResult = false;
      return;
    }

    const index: number = this.filteredList.indexOf(this.selectedItem);

    if (event.key == KEY_CODE.ENTER) {
      this.control.setValue(this.selectedItem?.title, { emitEvent: false });
      this.inputText = this.selectedItem?.title;
      this.onSelect(this.selectedItem);
      this.control?.markAllAsTouched();
      event.preventDefault();
      return;
    }

    // Enter or Esc (Esc used by IE/Edge)
    if (event.key == KEY_CODE.ESC || event.key == KEY_CODE.ESCAPE) {
      this.toggleDropdown();
      this.control?.markAllAsTouched();
      event.preventDefault();
      return;
    }

    // Space
    if (event.key == KEY_CODE.SPACE) {
      this.toggleDropdown();
      event.preventDefault();
      return;
    }

    // Tab
    if (event.key == KEY_CODE.TAB) {
      this.toggleDropdown();
      this.control?.markAllAsTouched();
      event.preventDefault();
      return;
    }
  }

  isSelected(item: string): boolean {
    return item === this.control?.value;
  }

  searchQueryOnDataSource($event): void {
    this.isNoResult = false;
    if ($event.target?.value?.length === 0) {
      this.isDropdownOpen = false;
    }

    this.onSearchChange.emit($event.target?.value);
  }

  resetForm() {
    this.inputText = '';
    this.selectedItem = null;
    this.onSearchChange.emit(this.inputText);
    this.onSelectionChange.emit(this.selectedItem);
    this.isDropdownOpen = false;
  }

  onSelect(selectedItem: DropdownItem): void {
    this.selectedItem = selectedItem;
    this.control?.setValue(selectedItem?.title, { emitEvent: false });
    this.control?.markAllAsTouched();
    this.toggleDropdown();
    this.inputText = this.selectedItem?.title;
    this.onSelectionChange.emit(selectedItem);
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onCannotFindEmployerClick() {
    this.isDropdownOpen = false;
    this.cannotFindEmployer.emit();
  }

  get isInvalid(): boolean {
    return this.control?.touched && this.control?.invalid && !this.isDropdownOpen;
  }

  get requiredFieldValidationError(): boolean {
    return this.title && this.control?.touched && this.control?.errors && this.control?.errors['required'];
  }

  get displayCannotFindEmployer(): boolean {
    return this.title && !this.isNoResult && this.isDropdownOpen;
  }

  scrollToElement(): void {
    document?.querySelector('.selected')?.scrollIntoView({ block: 'center' });
  }

  ngOnDestroy(): void {
    this.subscribe$.next();
    this.subscribe$.complete();
  }
}
