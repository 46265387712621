import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DropdownItem } from '@experian-digital/affordability-library';

export class CustomValidators {
  static noSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }
}

export class AutoCompleteDropdownValidator {
  static allowedItems(list: DropdownItem[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allowedItems = list.map((item) => item.title);
      if (allowedItems.indexOf(control.value) === -1) {
        return { allowedItem: true };
      }

      return null;
    };
  }
}
