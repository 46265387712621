import { LibFillCrossRoundIconComponent } from './../icons/lib-fill-cross-round-icon/lib-fill-cross-round-icon.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LibTextInputComponent } from './lib-input.component';
import { LibFillTickRoundIconModule } from '../icons/lib-fill-tick-round-icon/lib-fill-tick-round-icon.module';
import { LibFillCrossRoundIconModule } from '../icons/lib-fill-cross-round-icon/lib-fill-cross-round-icon.module';

@NgModule({
    declarations: [LibTextInputComponent],
    imports: [CommonModule, ReactiveFormsModule, LibFillTickRoundIconModule, LibFillCrossRoundIconModule],
    exports: [LibTextInputComponent],
})
export class LibTextInputModule { }
