import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Address, AddressIndex, AddressListItem } from '../../models/address.interface';
import { ArrayUtils } from '../../shared/utils/array.utils';
import { DateValidators } from '../../shared/validators/date.validator';
import { NUMBER_PATTERN, RICH_STRING_NUMBER_PATTERN, RICH_STRING_PATTERN } from '../../shared/validators/patterns';
import { PostcodeValidator } from '../../shared/validators/postcode.validator';
import { MONTHS } from './lib-multiple-address.helper';

@Component({
  selector: 'lib-multiple-addresses',
  templateUrl: './lib-multiple-addresses.component.html',
  styleUrls: ['./lib-multiple-addresses.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('true', style({ transform: 'rotate(0)' })),
      state('false', style({ transform: 'rotate(-180deg)', top: '-5px', position: 'relative' })),
      transition('false => true', animate('200ms ease-out')),
      transition('true => false', animate('200ms ease-in')),
    ]),
    trigger('accordionBody', [
      state('false', style({ height: 0 })),
      state('true', style({ height: '*' })),
      transition('true => false', animate('200ms ease-in')),
      transition('false => true', animate('200ms ease-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibMultipleAddressesComponent implements OnInit, AfterViewInit {
  @Input() addressFormGroups: UntypedFormArray;
  @Input() addressLists: AddressListItem[][] = [[]];
  @Input() selectedAddresses: Address[] = [];
  @Input() addressLoadingMessageList: string[] = [];
  @Input() isAddressShown = [];
  @Input() isPreviousAddressEnabled: boolean;
  @Input() isMoveInDateEnabled: boolean;
  @Input() events: Observable<void>;
  // NOTE: Scroll does not work correctly with fixed header, this
  // Input allows an offset which will be taken into account on scroll
  @Input() headerHeightOffset = 0;
  @Input() showOldSpinner: boolean = true;

  @Output() getAddressList = new EventEmitter();
  @Output() getAddress = new EventEmitter<AddressIndex>();

  @ViewChildren('address', { read: ElementRef }) addresses: QueryList<ElementRef>;

  formSubscriptions: Subscription[] = [];
  showButton = true;
  dateInfo = { months: MONTHS, years: ArrayUtils.generateArrayOfYears(100) };
  BaseDateValidators = [Validators.required, Validators.pattern(NUMBER_PATTERN)];
  isNewAddressAdded: boolean = false;
  showCountry: boolean = false;
  showMoveInDate: boolean = true;
  constructor(private fb: UntypedFormBuilder, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.addressFormGroups?.controls?.length === 0) {
      this.createNewAddressForm(0);
    }
    else {
      const isBeforeThreeYears = this.isBeforeThreeYearsAddress()
      if (this.addressFormGroups.length === 3 || isBeforeThreeYears) {
        this.showButton = false;
      }
      this.addressFormGroups.controls.forEach((control, i) => {
        this.formSubscriptions.push(
          this.addressFormGroups.controls[i].valueChanges.subscribe(() => {
            this.updateAddresses(i);
          }),
        );
      })
    }
    this.showCountry = (this.isMoveInDateEnabled || this.isPreviousAddressEnabled) ? true : false;
  }

  ngAfterViewInit(): void {
    this.addresses.changes.subscribe((li: QueryList<ElementRef>) => {
      if (this.addresses && this.addresses.last) {
        this.addresses.last.nativeElement.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    });
  }

  resetAddress(index: number): void {
    this.showMoveInDate = !this.showMoveInDate;
    this.cd.detectChanges();
    this.addressFormGroups?.controls[index]?.reset();
    this.addressFormGroups?.controls[index]?.get('day')?.patchValue('01');
    this.addressFormGroups?.controls[index]?.get('country')?.patchValue('UK');
    this.showMoveInDate = !this.showMoveInDate;
  }

  updateAddresses(index: number): void {
    this.addressFormGroups.updateValueAndValidity();
    if (this.isPreviousAddressEnabled || this.isMoveInDateEnabled) {
      const moveInDate =
        this.addressFormGroups.controls[index]?.get('year').value +
        '-' +
        this.addressFormGroups.controls[index]?.get('month').value +
        '-' +
        this.addressFormGroups.controls[index]?.get('day').value;
      const isBeforeThreeYears = moment(moveInDate, 'YYYY-M-D', true).isBefore(moment().subtract(3, 'years'));
      const dateValid = this.addressFormGroups.controls[index]?.valid;
      const isLastThreeAddresses = this.addressFormGroups.length === 3 ? true : false;
      this.showButton = true;
      if (this.addressFormGroups.controls[index]?.valid) {
        if (dateValid && (isBeforeThreeYears || isLastThreeAddresses)) {
          this.showButton = false;
          // Removes all future addresses as this one now satisfies
          if (!!this.addressFormGroups.controls[index + 1] && isBeforeThreeYears) {
            this.addressFormGroups.controls.forEach((e, i) => {
              this.deleteAddress(index + 1);
            });
          }
        }
        else {
          const isBeforeThreeYearsSatisfied = this.isBeforeThreeYearsAddress();
          isBeforeThreeYearsSatisfied ? this.showButton = false : this.showButton = true;
        }
      }
    }
  }

  isBeforeThreeYearsAddress(): boolean {
    if (this.isPreviousAddressEnabled || this.isMoveInDateEnabled) {
      const moveInDate =
        this.addressFormGroups?.controls[this.addressFormGroups?.length - 1]?.get('year').value +
        '-' +
        this.addressFormGroups?.controls[this.addressFormGroups?.length - 1]?.get('month').value +
        '-' +
        this.addressFormGroups?.controls[this.addressFormGroups?.length - 1]?.get('day').value;
      const isBeforeThreeYears = moment(moveInDate, 'YYYY-M-D', true).isBefore(moment().subtract(3, 'years'));
      return isBeforeThreeYears;
    }
    return true;
  }

  deleteAddress(index: number): void {
    this.addressFormGroups.removeAt(index);
    this.isAddressShown.splice(index, 1);
    this.formSubscriptions[index]?.unsubscribe();
    this.addressLists.splice(index, 1);

    if (this.addressFormGroups.length === 1) {
      this.isAddressShown[0] = true;
    }

    if (this.addressFormGroups?.valid && this.addressFormGroups.length < 3) {
      this.showButton = true;
    }
  }

  createNewAddressForm(index: number): void {
    this.addressFormGroups.controls.forEach((control, i) => {
      if (control.invalid) {
        this.addressFormGroups.controls[i].markAllAsTouched();
      }
    });

    if (this.addressFormGroups?.invalid) {
      return;
    }

    this.newAddressFormGroup(index);

    this.isAddressShown.push(true);
    this.isAddressShown[index - 1] = false;

    this.formSubscriptions.push(
      this.addressFormGroups.controls[index].valueChanges.subscribe(() => {
        this.updateAddresses(index);
      }),
    );

    this.isNewAddressAdded = true;
  }

  fetchAddressList(index: number): void {
    this.getAddressList.emit(index);
  }

  fetchAddress(selectedAddressIndex: number, formArrayIndex: number): void {
    this.getAddress.emit({ "selectedAddressIndex": selectedAddressIndex, "formArrayIndex": formArrayIndex });
  }

  toggleAddressShown(index: number): void {
    this.isAddressShown[index] = !this.isAddressShown[index];
  }

  onDropdownChange(index: number, value: string): void {
    // NOTE: For retrospective validation of all previous dates against current date
    // this.addressFormGroups.controls.forEach((e) => e.get('dateOfMove').updateValueAndValidity());
    this.addressFormGroups.controls[index].get(value).updateValueAndValidity();
  }

  workOutOffset(i: number): number {
    // NOTE: headerHeightOffset is fixed header height (if needed), 62 is
    // the height of closed accordion + margin, so on scroll the end
    // user will still have some contect of where they are on page.
    const accordionBuffer = 62;
    return this.headerHeightOffset + (i !== 0 ? 62 : 0);
  }

  private newAddressFormGroup(index: number): void {

    //addressFormGroups.controls.push(this.buildAddressForm());
    this.addressFormGroups.push(this.fb.group({}));

    // Add new address group so we can distinguish
    this.addressFormGroups.controls[index] = this.buildAddressForm(index);

    this.addressFormGroups.controls[index].updateValueAndValidity();

    this.addressFormGroups.updateValueAndValidity();
  }

  private buildAddressForm(index: number): UntypedFormGroup {
    let month = '';
    let year;

    if (this.isMoveInDateEnabled || this.isPreviousAddressEnabled) {
      return this.fb.group(
        {
          postcode: ['', [Validators.required, PostcodeValidator.validate]],
          flat: ['', [Validators.maxLength(30), Validators.pattern(RICH_STRING_NUMBER_PATTERN)]],
          houseName: ['', [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(RICH_STRING_PATTERN)]],
          houseNumber: ['', [Validators.maxLength(10), Validators.pattern(NUMBER_PATTERN)]],
          streetName: [
            '',
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(50),
              Validators.pattern(RICH_STRING_PATTERN),
            ],
          ],
          town: [
            '',
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(50),
              Validators.pattern(RICH_STRING_PATTERN),
            ],
          ],
          day: ['01'],
          month: [month, [...this.BaseDateValidators, Validators.maxLength(2), Validators.min(1), Validators.max(12),
          DateValidators.checkOtherDates(index, this.addressFormGroups),
          DateValidators.checkChosenDateInFutureArray(index, this.addressFormGroups),
          DateValidators.checkCurrentAfterNextDate(index, this.addressFormGroups),
          DateValidators.checkMoveInDateIsValid(index, this.addressFormGroups)
          ]],
          year: [
            year,
            [
              ...this.BaseDateValidators,
              Validators.maxLength(4),
              Validators.minLength(4),
              Validators.min(1900),
              Validators.max(new Date().getFullYear()),
              DateValidators.checkOtherDates(index, this.addressFormGroups),
              DateValidators.checkChosenDateInFutureArray(index, this.addressFormGroups),
              DateValidators.checkCurrentAfterNextDate(index, this.addressFormGroups),
              DateValidators.checkMoveInDateIsValid(index, this.addressFormGroups)
            ],
          ],
          country: ['UK', [Validators.required]], // By default UK will be pre-populated
        }
      );
    }

    return this.fb.group({
      postcode: ['', [Validators.required, PostcodeValidator.validate]],
      flat: ['', [Validators.maxLength(30), Validators.pattern(RICH_STRING_NUMBER_PATTERN)]],
      houseName: ['', [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(RICH_STRING_PATTERN)]],
      houseNumber: ['', [Validators.maxLength(10), Validators.pattern(NUMBER_PATTERN)]],
      streetName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(RICH_STRING_PATTERN),
        ],
      ],
      town: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(RICH_STRING_PATTERN),
        ],
      ],
    });
  }

}
