import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {
  AvailableDepartment,
  Error,
  Loading,
  STORAGE_KEYS,
  StoreService,
} from '@experian-digital/affordability-library';
import { APP_ROUTES } from '@portal-app/advisor/routes';
import { NO_DEPARTMENTS_ERROR, NO_LINK_ERROR } from '@portal-shared/models/enums/error.enum';
import { DepartmentService } from '@portal-shared/services/department.service';
import { ErrorService } from '@portal-shared/services/error.service';
import { STORE_FIELDS } from '@portal-store/models/store.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SelectDepartmentGuard {
  constructor(
    private router: Router,
    private store: StoreService,
    private departmentService: DepartmentService,
    private errorService: ErrorService,
  ) {}

  state: RouterStateSnapshot;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.state = state;
    return this.requestDepartments();
  }

  private requestDepartments(): Observable<boolean> {
    const storedDepartments = this.store.get<AvailableDepartment[]>(STORE_FIELDS.availableDepartments);

    return (storedDepartments?.length ? of(storedDepartments) : this.departmentService.getUserDepartments()).pipe(
      map((departments: AvailableDepartment[]): any => {
        if (!departments.length) {
          throw NO_DEPARTMENTS_ERROR;
        }
        this.store.add<AvailableDepartment[]>(STORE_FIELDS.availableDepartments, departments);
        return this.checkMultipleDepartment(departments);
      }),
      catchError((error: Error | HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          this.toggleError(NO_LINK_ERROR(), error);
          return of(false);
        }
        this.toggleError(NO_DEPARTMENTS_ERROR);
        return of(false);
      }),
    );
  }

  private checkMultipleDepartment(departments): boolean {
    const shouldRedirectToSelectDepartment =
      !this.state.url?.includes(APP_ROUTES.selectDepartment) && !localStorage.getItem(STORAGE_KEYS.departmentName);

    if (departments.length > 1) {
      if (shouldRedirectToSelectDepartment) {
        this.router.navigateByUrl(APP_ROUTES.selectDepartment);
        return false;
      }
      return true;
    }
    return this.oneDepartmentOnly(departments);
  }

  private oneDepartmentOnly(departments: AvailableDepartment[]): boolean {
    localStorage.setItem(STORAGE_KEYS.departmentName, departments[0].shortName);
    if (this.state.url?.includes(APP_ROUTES.selectDepartment)) {
      this.router.navigateByUrl(APP_ROUTES.home);
    }
    return true;
  }

  private toggleError(errorMessage: Error, error?: HttpErrorResponse): void {
    this.store.add<Loading>(STORE_FIELDS.loadingInitial, null);
    this.store.add<Error>(STORE_FIELDS.error, this.errorService.buildError(errorMessage, error));
  }
}
