export const enum LOADING_MESSAGES {
  standard = 'Please wait...',
}

export const enum LOADING_IMAGES {}

export const STANDARD_LOADER = {
  infoText: LOADING_MESSAGES.standard,
  imageUrl: '',
};
