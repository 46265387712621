import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Error } from '@portal-store/models/store.model';

@Component({
  selector: 'aff-error-message',
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent {
  @Input() error: Error;
  @Output() retry: EventEmitter<any> = new EventEmitter();

  callRetry($event): void {
    $event.preventDefault();
    this.retry.emit();
  }
}
