<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="11"
  viewBox="0 0 20 11"
  aria-labelledby="chevron-icon-title chevron-icon-desc"
  role="img"
  class="display-block"
>
  <title id="chevron-icon-title">Chevron icon</title>
  <desc id="chevron-icon-desc">Click to expand a dropdown or accordian.</desc>
  <path
    fill="#BA2F7D"
    fill-rule="evenodd"
    stroke="#BA2F7D"
    class="fill stroke"
    d="M10 2.176L1.88 9.857a.537.537 0 0 1-.73 0 .469.469 0 0 1 0-.689l8.487-8.025a.534.534 0 0 1 .73 0l8.482 8.025a.469.469 0 0 1 0 .69.537.537 0 0 1-.73 0L10 2.175z"
  />
</svg>
