import { Router } from '@angular/router';

export enum APP_ROUTES {
  advisor = 'advisor',

  home = 'home',
  applications = 'applications',
  customers = 'customers',
  sanctionReport = 'sanction-report',
  creditReport = 'credit-report',
  identity = 'identity',
  list = 'list',
  new = 'new',
  report = 'report',
  details = 'details',
  overview = 'overview',
  accounts = 'accounts',
  passport = 'passport',
  customerDetails = 'customer-details',
  events = 'events',
  transactions = 'transactions',
  insights = 'insights',
  jointApplications = 'joint-applications',
  unauthorised = 'unauthorised',
  incomeReport = 'income-report',
  payroll = 'payroll-data',
  hmrc = 'hmrc',
  employerCheck = 'employer-check',
  selectDepartment = 'select-department',
  idDocVerification = 'id-doc-verification',
  reportDownload = 'report-download',
  workReport = 'work-report',
  hybridPayroll = 'payroll',
  creditSummary = 'credit-summary',
}

export function currentRouteEquals(router: Router, route: string) {
  return router.url.indexOf(route) > -1;
}
