import { Inject, Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { AuthService, STORAGE_KEYS } from '@experian-digital/affordability-library';

import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { PortalRoles } from '../enums/portal-roles.enum';

@Injectable()
export class ApplicationAuthGuard {
  private portalType;
  constructor(
    private authService: AuthService,
    @Inject('portalType')
    portalType,
  ) {
    this.portalType = portalType;
  }

  canActivate(_, state: RouterStateSnapshot): Observable<boolean> {
    if (this.portalType === PortalRoles.Customer && state.url.includes('auth-faqs')) {
      return of(true);
    }
    return this.authService.isDoneLoading$.pipe(
      switchMap((_) => this.authService.isAuthenticated$),
      take(1),
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          if (this.portalType === PortalRoles.Advisor) {
            // User is not authenticated + no oktaLogin param means navigate back to user auth
            if (!new URLSearchParams(window.location.search).get('oktaLogin')) {
              localStorage.removeItem(STORAGE_KEYS.oktaLogin);
            }
            localStorage.removeItem(STORAGE_KEYS.departmentName);
          }
          this.authService.login(state.url);
          return false;
        }
        return isAuthenticated;
      }),
    );
  }
}
