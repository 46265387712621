export enum STORAGE_KEYS {
  applicationReference = 'applicationReference',
  bankId = 'bankId',
  departmentName = 'departmentName',
  departmentId = 'departmentId',
  departmentRef = 'departmentRef',
  applicationToken = 'applicationToken',
  errorDetails = 'errorDetails',
  employerId = 'employerId',
  employerVerified = 'employerVerified',
  cookieName = 'cookieName',
  cookieConsentName = 'cookieConsentName',
  oktaLogin = 'oktaLogin',
}
