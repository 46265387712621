import { AbstractControl, ValidationErrors } from '@angular/forms';

export class PostcodeValidator {
  static validate(control: AbstractControl): ValidationErrors | null {
    /* tslint:disable:max-line-length */
    const valid = /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/gi.test(
      control.value,
    );
    /* tslint:enable:max-line-length */
    return !valid ? { postcodeValue: true } : null;
  }
}
