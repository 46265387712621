<div [className]="classList">
  <fieldset
    (click)="disabled ? null : toggleDropdown()"
    [class.invalid-dropdown]="isInvalid"
    [class.disabled]="disabled"
    [attr.data-qa-id]="'dropdown-box'"
  >

    <!-- Selected item/placeholder and down arrow icon -->
    <legend
      *ngIf="title"
      class="ml-sm px-xxs"
      [class.invalid-field]="isInvalid"
    >{{title}}</legend>

    <div class="dropdown-input flex align-items-center">
      <input
        type="text"
        class="dropdown-input-box"
        [title]="selectedItem?.title"
        [ngClass]="{'dropdown-input-box-without-title' : title===undefined}"
        [spellcheck]="spellcheck"
        readonly
        (keydown.space)="toggleDropdown()"
        [value]="selectedItem ? selectedItem.title : placeholder"
        (focus)="focus()"
        (blur)="blur()"
        [disabled]="disabled"
      >
      <div
        class="dropdown-icon flex"
        [class.c-pointer]="!disabled"
      >
        <lib-down-arrow-icon class="icon-theme icon-xxs"></lib-down-arrow-icon>
      </div>
    </div>

    <!-- Dropdown list -->
    <div
      #dropDownList
      class="dropdown-list scrollable-list"
      [ngStyle]="{'z-index': customZindex ? customZindex : 100}"
      [class.dropdown-list--above]="showAbove"
      *ngIf="isDropdownOpen"
      [attr.data-qa-id]="'dropdown-list'"
    >
      <!-- Placeholder -->
      <p
        *ngIf="placeholder"
        class="list-item px-sm py-xxs placeholder"
        [class.selected]="!selectedItem"
        [attr.data-qa-id]="'placeholder'"
      >
        <span class="truncate-xxs">
          {{placeholder}}
        </span>
      </p>

      <!-- List -->
      <p
        class="list-item px-sm py-xxs c-pointer"
        [class.selected]="selectedItem?.value == option.value"
        *ngFor="let option of list ; let i = index"
        [attr.data-qa-id]="'list-item-' + i"
        (click)="onSelect(option)"
      >
        <span
          [title]="option.title"
          class="truncate-xxs"
        >
          {{option.title}}
        </span>
      </p>
    </div>
  </fieldset>

  <!-- Error message -->
  <span
    *ngIf="isInvalid"
    id="error-message"
    class="invalid-field"
  >{{errorMessage}}
  </span>
</div>
