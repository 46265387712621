import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-spinner',
  templateUrl: './lib-spinner.component.html',
  styleUrls: ['./lib-spinner.component.scss'],
})
export class LibSpinnerComponent {
  @Input() imageUrl: string;
  @Input() isSmall: boolean;

  getStyle(): object {
    if (!this.imageUrl) {
      return;
    }

    const bg = 'url("' + this.imageUrl + '")';
    return {
      background: bg,
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
      'background-size': '50%',
    };
  }
}
