import { Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  static appInsights: ApplicationInsights = null;

  static logEvent(name: string, properties?: { [key: string]: any }) {
    if (AppInsightsService.appInsights) {
      AppInsightsService.appInsights.trackEvent({ name }, properties);
    }
  }

  initAppInsights(instrumentationKey: string, isCookieEnabled = true): void {
    AppInsightsService.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        cookieCfg: {
          enabled: isCookieEnabled,
        },
      },
    });

    AppInsightsService.appInsights.loadAppInsights();
  }

  logException(exception: Error, severityLevel?: number) {
    if (AppInsightsService.appInsights) {
      AppInsightsService.appInsights.trackException({ exception, severityLevel });
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    if (AppInsightsService.appInsights) {
      AppInsightsService.appInsights.trackPageView({
        name,
        uri: url,
      });
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (AppInsightsService.appInsights) {
      AppInsightsService.appInsights.trackMetric({ name, average }, properties);
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (AppInsightsService.appInsights) {
      AppInsightsService.appInsights.trackTrace({ message }, properties);
    }
  }

  addTelemetry(customProperties: Map<string, string>): void {
    if (AppInsightsService.appInsights) {
      const telemetryInitializer = (envelope: ITelemetryItem) => {
        customProperties.forEach((value: string, key: string) => {
          envelope.data[key] = value;
        });
      };

      AppInsightsService.appInsights.addTelemetryInitializer(telemetryInitializer);
    }
  }

  enableCookies(isEnabled: boolean): void {
    AppInsightsService.appInsights.getCookieMgr().setEnabled(isEnabled);
  }
}
