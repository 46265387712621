import { Injectable } from '@angular/core';
import { LibModalService } from '@experian-digital/affordability-library';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CookieService {
  static isCookiePanelVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);

  static setCookiePanelVisibility(cookieName: string): void {
    const cookie = CookieService.readCookie(cookieName);
    CookieService.isCookiePanelVisible.next(cookie === null);
    LibModalService.isOverlayVisible.next(cookie === null);
  }

  static setCookie(cookieName: string, state: boolean): void {
    document.cookie = `${cookieName}=${state}; Path=/; Domain=${window.location.hostname};`;
  }

  static deleteCookie(cookieName: string): void {
    document.cookie =
      cookieName + `=; Path=/; Domain=${window.location.hostname}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  static readCookie(cookieName: string): boolean | null {
    const name = `${cookieName}=`;
    const cookieList = decodeURIComponent(document.cookie).split(';');

    for (let i = 0; i < cookieList.length; i++) {
      let cookie = cookieList[i];
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) == 0) {
        return JSON.parse(cookie.substring(name.length, cookie.length));
      }
    }
    return null;
  }
}
